import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import bg02 from "../../assets/video/EurekalandingVideo.mp4";
import { Link } from "react-router-dom";

export default class Section extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="home-slider position-relative ">
          <div className="carousel slide">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="bg-half-170">
                  <video
                    autoPlay
                    loop
                    muted
                    style={{
                      position: 'absolute',
                      width: '100%',
                      left: '50%',
                      top: '50%',
                      height: '90vh',
                      objectFit: 'cover',
                      transform: 'translate(-50%, -50%)',
                      zIndex: '-1',
                    }}
                    src={bg02}
                  ></video>
                  <div className="bg-overlay"></div>
                  <Container>
                    <Row className="justify-content-center">
                      <Col xs={12}>
                        <div className="title-heading text-center">
                          <h2 className="text-center title-dark fw-bold text-white mb-3 responsive-title">
                          Discover the World, Rediscover Yourself
                          </h2>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
