import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  Row,
  NavDropdown,
} from "react-bootstrap";
import { useAuth } from "../../../context/Authcontext";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../assets/css/navbar.css";
import userImg from "../../../assets/image/UserImg.png";
import { toast } from "react-toastify";
import UpdateProfile from "../../Login/UpdateProfile";
import LoginPopup from "../../Login/LoginPopup";
import eurekaImg from "../../../assets/image/EurekaTrip.png";
import adventureIcon from "../../../assets/image/tourism.png";
import transformationIcon from "../../../assets/image/data-transformation (1).png";
import experienceIcon from "../../../assets/image/talent-management.png";
import spiritualIcon from "../../../assets/image/mandala.png";
import { BiMenu, BiUser, BiStoreAlt, BiBrain } from "react-icons/bi";

const NavbarHome = ({ pageName, adventureNavbar }) => {
  const [activeTab, setActiveTab] = useState(pageName || null);
  const [isMobileVisible, setIsMobileVisible] = useState(false);
  const [showFullName, setShowFullName] = useState(false);
  const [handleUpdatePopup, setHandleUpdatePopup] = useState(false);
  const [handleLoginPopup, setHandleLoginPopup] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showforgotpassword, setshowforgotpassword] = useState(false);
  const [showAdventureNavbar, setShowAdventureNavbar] = useState(false);
  const [showCategories, setShowCategories] = useState([]);
  const [CategoryId, setCategoryId] = useState();
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const [userData, setUserData] = useState({});
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const { user, logout } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);

  const isActive = (path) => {
    return location.pathname === path || activeTab === path;
  };

  useEffect(() => {
    fetchCategories();
    setUserData(user);

    const handleResize = () => {
      window.innerWidth <= 768
        ? setIsMobileVisible(true)
        : setIsMobileVisible(false);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [user]);

  const handleNameClick = () => {
    setShowFullName(!showFullName);
  };

  const toggleLoginFormVisibility = () => {
    setHandleLoginPopup(true);
    setShowRegisterForm(false);
  };

  const closeLoginForm = () => {
    setHandleLoginPopup(false);
    setShowRegisterForm(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
    window.location.reload();
  };

  const handleLoginSuccess = () => {
    closeLoginForm();
    navigate("/");
  };

  const handleMyTourPage = () => {
    navigate("/booking-details");
  };

  const handleMyWishlist = () => {
    navigate("/whishlist-tour");
  };

  const handleUpdateProfile = () => {
    setHandleUpdatePopup(true);
  };

  const handleClose = () => setHandleUpdatePopup(false);

  const handleTabClick = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/categories`);
      const data = await response.json();
      setShowCategories(data.data);
      setCategoryId(data.data[0].categoryId);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryClick = (categoryId) => {
    setActiveCategoryId(categoryId);
    navigate(`/adventure`, { state: { categoryId } });
  };

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };


    // Close dropdown if clicked outside
    useEffect(() => {
      function handleClickOutside(event) {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsNavbarOpen(false); // Close the dropdown
        }
      }
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownRef]);


  return (
    <>
      {!isMobileVisible && (
        <div className="fixed-top">
          <Row>
            <Col xs={12}>
              <Navbar
                variant="dark"
                expand="lg"
                className="navbar-custom"
                fixed="top"
              >
                <Container fluid>
                  <Navbar.Brand
                    className="brand-custom ms-2"
                    onClick={() => navigate("/")}
                  >
                    <span className="eurekatrip-text">
                      <img
                        className="eureka-Logo"
                        src={eurekaImg}
                        alt="Eureka"
                      />
                    </span>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />

                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto nav-links-custom" style={{marginLeft:'-48px'}}>
                      <Nav className="themes">
                        <Nav.Link
                          onClick={() => handleTabClick("/adventure")}
                          className={
                            isActive("/adventure") ? "active-nav-tab" : ""
                          }
                        >
                          <span className="adventure-text">Adventure</span>
                        </Nav.Link>
                        <Nav.Link
                          onClick={() => handleTabClick("/transformation")}
                          className={
                            isActive("/transformation") ? "active-nav-tab" : ""
                          }
                        >
                          <span className="transformation-text">
                            Transformation
                          </span>
                        </Nav.Link>
                        <Nav.Link
                          onClick={() => handleTabClick("/experience")}
                          className={
                            isActive("/experience") ? "active-nav-tab" : ""
                          }
                        >
                          <span className="transformation-text">
                            Experience
                          </span>
                        </Nav.Link>
                        <Nav.Link
                          onClick={() => handleTabClick("/spiritual")}
                          className={
                            isActive("/spiritual") ? "active-nav-tab" : ""
                          }
                        >
                          <span className="spiritual-text">Spiritual</span>
                        </Nav.Link>
                      </Nav>
                    </Nav>

                    <Nav className="d-flex align-items-center justify-content-end">
                      <Button
                        variant=""
                        className="bg-white build-trip-button text-wrap"
                        onClick={() => navigate("/personalizedTrip")}
                        // onClick={() => navigate("/newlogin")}
                      >
                        <span className="trip-element text-wrap">
                          AI Recommendation Trip
                        </span>
                      </Button>

                      <Button
                        variant=""
                        className="bg-white build-trip-button ms-3"
                        onClick={() =>
                          window.open(
                            "https://vendorhub.eurekatrip.com/",
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                      >
                        <span className="trip-element">Become A Vendor</span>
                      </Button>

                      {!user && (
                        <Button
                          variant=""
                          className="bg-white build-trip-button ms-3"
                          onClick={() => navigate("/login")}
                        >
                          <i className="bi bi-person-fill me-1 text-white"></i>
                          <span className="trip-element text-center">
                            Login
                          </span>
                        </Button>
                      )}

                      {user && (
                        <Navbar>
                          <Navbar.Toggle aria-controls="basic-navbar-nav " />
                          <Navbar.Collapse id="basic-navbar-nav">
                            <NavDropdown
                              title={
                                <span className="d-flex justify-content-center align-items-center">
                                  <span className="circle-icon">
                                    {user?.firstName
                                      ? user.firstName.charAt(0)
                                      : ""}
                                  </span>
                                </span>
                              }
                              id="navbarScrollingDropdown"
                              className="me--4 "
                              align="end"
                            >
                              <NavDropdown.Item onClick={handleUpdateProfile}>
                                <i className="bi bi-person-fill me-2"></i>
                                <span className="nav-dropdown-item">
                                  My Account
                                </span>
                              </NavDropdown.Item>
                              <NavDropdown.Item onClick={handleMyTourPage}>
                                <i className="bi bi-briefcase-fill me-2"></i>
                                <span className="nav-dropdown-item">
                                  {" "}
                                  My Tours
                                </span>
                              </NavDropdown.Item>
                              <NavDropdown.Item onClick={handleMyWishlist}>
                                <i className="bi bi-heart-fill me-2"></i>
                                <span className="nav-dropdown-item">
                                  My Wishlist
                                </span>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/" onClick={handleLogout}>
                                <i className="bi bi-box-arrow-right me-2"></i>
                                <span className="nav-dropdown-item">
                                  Logout
                                </span>
                              </NavDropdown.Item>
                            </NavDropdown>
                          </Navbar.Collapse>
                        </Navbar>
                      )}
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Col>
          </Row>
        </div>
      )}

      {isMobileVisible && (
        <div className="fixed-top font-common">
          <Row>
            <Col xs={12}>
              <Navbar expand="lg" className="navbar-custom">
                <Navbar.Brand
                  onClick={() => navigate("/")}
                  className="brand-custom"
                >
                  <img src={eurekaImg} className="eureka-Logo" alt="Eureka" />
                </Navbar.Brand>

                <Button
                  variant="outline-secondary"
                  className="d-flex align-items-center justify-content-between menu-profile-btn"
                  onClick={handleNavbarToggle}
                >
                  <BiMenu size={24} className="me-2" />
                  <BiUser size={24} />
                </Button>

                {isNavbarOpen && (
                  <div ref={dropdownRef} className="custom-dropdown">
                    {!user && (
                      <Button
                        variant=""
                        className=" text-start"
                        onClick={() => navigate("/login")}
                      >
                        <i className="bi bi-person-fill me-2 "></i>Log In / Sign
                        Up
                      </Button>
                    )}

                    {user && (
                      <>
                        <NavDropdown.Item
                          onClick={handleUpdateProfile}
                          className="text-start"
                        >
                          <i className="bi bi-person-fill me-2"></i> My Account
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={handleMyTourPage}
                          className="text-start"
                        >
                          <i className="bi bi-briefcase-fill me-2"></i> My Tours
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          onClick={handleMyWishlist}
                          className="text-start"
                        >
                          <i className="bi bi-heart-fill me-2"></i> My Wishlist
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href="/"
                          onClick={handleLogout}
                          className="text-start"
                        >
                          <i className="bi bi-box-arrow-right me-2"></i> Logout
                        </NavDropdown.Item>
                      </>
                    )}
                    <Button
                      variant=""
                      className="text-start"
                      onClick={() =>
                        window.open(
                          "https://vendorhub.eurekatrip.com/",
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    >
                      <BiStoreAlt size={20} className="me-2" />
                      Become A Vendor
                    </Button>

                    <Button
                      variant=""
                      className="text-start text-wrap my-2"
                      onClick={() => navigate("/personalizedTrip")}
                    >
                      <BiBrain size={20} className="me-2" />
                      AI Recommendation Trip
                    </Button>
                  </div>
                )}
              </Navbar>
            </Col>
          </Row>

          <div className="floating-bottom-bar fixed-bottom">
            <div
              className={isActive("/adventure") ? "item item-active" : "item"}
              onClick={() => handleTabClick("/adventure")}
            >
              <div>
                <img
                  src={adventureIcon}
                  alt="Adventure"
                  className="category-icon"
                />
              </div>
              <div className="item-name">Adventure</div>
            </div>

            <div
              className={
                isActive("/transformation") ? "item item-active" : "item"
              }
              onClick={() => handleTabClick("/transformation")}
            >
              <div>
                <img
                  src={transformationIcon}
                  alt="Transformation"
                  className="category-icon"
                />
              </div>
              <div className="item-name">Transformation</div>
            </div>

            <div
              className={isActive("/experience") ? "item item-active" : "item"}
              onClick={() => handleTabClick("/experience")}
            >
              <div>
                <img
                  src={experienceIcon}
                  alt="Experience"
                  className="category-icon"
                />
              </div>
              <div className="item-name">Experience</div>
            </div>

            <div
              className={isActive("/spiritual") ? "item item-active" : "item"}
              onClick={() => handleTabClick("/spiritual")}
            >
              <div>
                <img
                  src={spiritualIcon}
                  alt="Spiritual"
                  className="category-icon"
                />
              </div>
              <div className="item-name">Spiritual</div>
            </div>
          </div>
        </div>
      )}

      {/* {isMobileVisible && (
        <div className="fixed-top">
          <Row>
            <Col xs={12}>
              <Row className="nav-bar">
                <div>
                  <div className="d-inline">
                    <img
                      onClick={() => navigate("/")}
                      className="eureka-Logo"
                      src={eurekaImg}
                      alt="Eureka"
                    ></img>
                  </div>

                  <div className="float-end me-1 mt-1">
                    {user ? (
                      <NavDropdown
                        title={
                          <span className="d-flex justify-content-center align-items-center profile-name">
                            <span className="circle-icon">
                              {user?.firstName ? user.firstName.charAt(0) : "?"}
                            </span>
                          </span>
                        }
                        id="navbarScrollingDropdown"
                        className="me-1"
                        align="end"
                      >
                        <NavDropdown.Item onClick={handleUpdateProfile}>
                          <i className="bi bi-person-fill me-2"></i>
                          <span className="nav-dropdown-item">My Account</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={handleMyTourPage}>
                          <i className="bi bi-briefcase-fill me-2"></i>
                          <span className="nav-dropdown-item"> My Tours</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={handleMyWishlist}>
                          <i className="bi bi-heart-fill me-2"></i>
                          <span className="nav-dropdown-item">My Wishlist</span>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/" onClick={handleLogout}>
                          <i className="bi bi-box-arrow-right me-2"></i>
                          <span className="nav-dropdown-item">Logout</span>
                        </NavDropdown.Item>
                      </NavDropdown>
                    ) : (
                      <Button
                        variant=""
                        className="p-2 bg-white build-trip-button ms-3"
                        // onClick={toggleLoginFormVisibility}
                        onClick={() => navigate("/login")}

                      >
                        <i className="bi bi-person-fill me-2 text-white"></i>
                        <span className="trip-element">Login</span>
                      </Button>
                    )}
                  </div>
                </div>
              </Row>

              <div className="floating-bottom-bar fixed-bottom">
                <div
                  className={
                    isActive("/adventure") ? "item item-active" : "item"
                  }
                  onClick={() => handleTabClick("/adventure")}
                >
                  <div>
                    <img src={adventureIcon} alt="" className="category-icon" />
                  </div>
                  <div className="item-name">Adventure</div>
                </div>

                <div
                  className={
                    isActive("/transformation") ? "item item-active" : "item"
                  }
                  onClick={() => handleTabClick("/transformation")}
                >
                  <div>
                    <img
                      src={transformationIcon}
                      alt=""
                      className="category-icon"
                    />
                  </div>
                  <div className="item-name">Transformation</div>
                </div>

                <div
                  className={
                    isActive("/experience") ? "item item-active" : "item"
                  }
                  onClick={() => handleTabClick("/experience")}
                >
                  <div>
                    <img
                      src={experienceIcon}
                      alt=""
                      className="category-icon"
                    />
                  </div>
                  <div className="item-name">Experience</div>
                </div>

                <div
                  className={
                    isActive("/spiritual") ? "item item-active" : "item"
                  }
                  onClick={() => handleTabClick("/spiritual")}
                >
                  <div>
                    <img src={spiritualIcon} alt="" className="category-icon" />
                  </div>
                  <div className="item-name">Spiritual</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )} */}

      {showAdventureNavbar && <div></div>}

      {!user && (
        <LoginPopup
          onLoginSuccess={handleLoginSuccess}
          showLoginPopup={handleLoginPopup}
          handleLoginClose={closeLoginForm}
          showRegisterForm={showRegisterForm}
          setShowRegisterForm={setShowRegisterForm}
          setHandleLoginPopup={setHandleLoginPopup}
          showforgotpassword={showforgotpassword}
          setshowforgotpassword={setshowforgotpassword}
        />
      )}
      {handleUpdatePopup && user && (
        <UpdateProfile
          showUpdatePopup={handleUpdatePopup}
          handleClose={handleClose}
          userData={user}
        />
      )}
    </>
  );
};

export default NavbarHome;
