import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
// import { GoogleOAuthProvider } from '@react-oauth/google';

// import { GoogleLogin } from 'react-google-login';
import FacebookLogin from "react-facebook-login";
import TwitterLogin from "react-twitter-auth";
import "../../assets/css/login.css";

const SocialLogin = ({ onSocialLoginSuccess }) => {
  const GOOGLE_CLIENT_ID =
    "634684914119-vpvroptblisths8trb6la0r8r9sruvdv.apps.googleusercontent.com";
  const FACEBOOK_APP_ID = "937835227191548";
  const TWITTER_LOGIN_URL =
    "https://eurekatrip-user-service.azurewebsites.net/auth/twitter";
  const TWITTER_REQUEST_TOKEN_URL =
    "https://eurekatrip-user-service.azurewebsites.net/auth/twitter/request_token";

  const responseGoogle = (response) => {
    console.log(response);
    onSocialLoginSuccess(response.credential, "google");
  };

 
  

  return (
    <div>
      <div className="social-button">
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <GoogleLogin onSuccess={responseGoogle}
           />
        </GoogleOAuthProvider>
      </div>
    </div>
  );
};

export default SocialLogin;
