import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Form, Button, Col, Row } from "react-bootstrap";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import SocialLogin from "./SocialLogin";
import { useAuth } from "../../context/Authcontext";

function SetPasswordForm({ email, onPasswordSetSuccess, forgotEmail }) {
    const [password, setNewPassword] = useState('');
    const [confirmPassword, setNewConfirmPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    // const [showLoginFormAfterPasswordSet, setShowLoginFormAfterPasswordSet] = useState(false);
    const [showRegisterForm, setShowRegisterForm] = useState(false);
    const [showForgotPasswordForm, setShowForgotPasswordForm] = useState(false);
    
    const { user, login } = useAuth();
    const [credentials, setCredentials] = useState({ username: "", password: "" });
    const navigate = useNavigate();

    const [formErrors, setFormErrors] = useState({});
    const BaseAPI = process.env.REACT_APP_AUTH_SERVER_ENDPOINT;

    useEffect(() => {
        const errors = validatePassword(password, confirmPassword);
        setFormErrors(errors);
    }, [password, confirmPassword]);

    const validatePassword = (password, confirmPassword) => {
        let errors = {};
        if (!password) {
            errors.passwordRequired = "Password is required";
        } else {
            if (!/[a-z]/.test(password)) errors.lowercase = "At least one lowercase letter required";
            if (!/[A-Z]/.test(password)) errors.uppercase = "At least one uppercase letter required";
            if (!/[0-9]/.test(password)) errors.number = "At least one number required";
            if (!/[\!\@\#\$\%\^\&\*]/.test(password)) errors.specialChar = "At least one special character required (!@#$%^&*)";
            if (password.length < 8) errors.length = "Password must be at least 8 characters long";
        }
        if (password && confirmPassword && password !== confirmPassword) {
            errors.confirmPasswordMatch = "Passwords do not match";
        }
        return errors;
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setNewConfirmPassword(event.target.value);
    };

    const togglePasswordVisibility = () => setPasswordShown(!passwordShown);
    const toggleConfirmPasswordVisibility = () => setConfirmPasswordShown(!confirmPasswordShown);

    const setPasswordHandler = async (e) => {
        e.preventDefault();
        if (Object.keys(formErrors).length === 0) {
            const Data = {
                email: email || forgotEmail,
                password: password,
                confirmPassword: confirmPassword
            };
            try {
                const response = await fetch(`${BaseAPI}/rest/auth/set-password`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(Data),
                });
                const data = await response.json();
                console.log("data", data);
                if (response.ok) {
                    if (data.statusCode === 200) {
                        toast.success("Password set successfully, Please Login ", { autoClose: 3000 });
                        onPasswordSetSuccess(true);
                        // navigate("/")
                    }
                } else {
                    throw new Error(data.message || "Error setting password");
                }
            } catch (error) {
                toast.error(error.message, { autoClose: 3000 });
            }
        }
    };

    const handleSocialLoginSuccess = () => {
        navigate("/");
    };

    const handleRegisterPopup = () => {
        setShowRegisterForm(true);
    };

    const handleforgotPopup = () => {
        setShowForgotPasswordForm(true);
    };

    const handleEmailChange = (event) => {
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            username: event.target.value,
        }));
    };

    const handlePasswordChange = (event) => {
        setCredentials((prevCredentials) => ({
            ...prevCredentials,
            password: event.target.value,
        }));
    };

    const handleLogin = async () => {
        try {
            const userEmail = credentials.username;
            const response = await fetch(
                ` ${BaseAPI}/rest/auth/userEmail/${userEmail}`,
                { method: "GET" }
            );
            if (!response.ok) {
                throw new Error("Invalid Credential, \n Please try again.");
            }
            const user = await response.json();

            const credentialsJSON = JSON.stringify(credentials);

            const loginResponse = await fetch(`${BaseAPI}/rest/auth/login`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: credentialsJSON,
            });

            if (!loginResponse.ok) {
                throw new Error("Incorrect username or password");
            }

            const data = await loginResponse.json();

            toast.success("Login successful");

            localStorage.setItem("user", JSON.stringify(data.data));
            login(data.data);
            navigate("/");
            window.location.reload();
        } catch (error) {
            console.error("Login failed:", error);
            toast.error(error.message);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleLogin();
        }
    };

    return (
        <>
          <ToastContainer position="top-center" />
                <div className="set-password-form">
                    <h4>Set Password</h4>
                    <Form>
                        <Row>
                            <Col sm={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type={passwordShown ? "text" : "password"}
                                        value={password}
                                        onChange={handleNewPasswordChange}
                                        isInvalid={Object.keys(formErrors).length > 0}
                                    />
                                    <div className="password-toggle-icon eye-button float-end me-4" onClick={togglePasswordVisibility}>
                                        {passwordShown ? <BsEye /> : <BsEyeSlash />}
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {Object.values(formErrors).map((error, index) => <div key={index}>{error}</div>)}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type={confirmPasswordShown ? "text" : "password"}
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        isInvalid={!!formErrors.confirmPasswordMatch}
                                    />
                                    <div className="password-toggle-icon eye-button float-end me-4" onClick={toggleConfirmPasswordVisibility}>
                                        {confirmPasswordShown ? <BsEye /> : <BsEyeSlash />}
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {formErrors.confirmPasswordMatch}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button onClick={setPasswordHandler}>Set Password</Button>
                    </Form>
                </div>

        </>
    );
}

export default SetPasswordForm;
