import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Circle,
  InfoWindow,
} from "@react-google-maps/api";

const customIcon = {
  url: "../../assets/image/Star 13.png",
  scaledSize: new window.google.maps.Size(38, 38),
  anchor: new window.google.maps.Point(19, 37),
};

const MapView = ({ locationData }) => {
  console.log("locationdata=================>", locationData);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCkh6K-m8jZSrwZ_3ZiCQGa5HSLBCLcYvE",
    libraries: ["places"],
  });

  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const [selectedLocation, setSelectedLocation] = useState(null);
  useEffect(() => {
    if (locationData && isLoaded) {
      if (locationData.placeId) {
        const service = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails(
          {
            placeId: locationData.placeId,
          },
          (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              setMapCenter(place.geometry.location.toJSON());
              setSelectedLocation(place.geometry.location.toJSON());
            }
          }
        );
      } else if (locationData.latitude && locationData.longitude) {
        const latLng = {
          lat: locationData.latitude,
          lng: locationData.longitude,
        };
        setMapCenter(latLng);
        setSelectedLocation(latLng);
      }
    }
  }, [locationData, isLoaded]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  const mapContainerStyle = {
    height: "400px",
    width: "100%",
  };

  const options = {
    zoom: 13,
  };

  const handleMarkerClick = () => {
    setSelectedLocation(mapCenter);
  };

  const handleCloseClick = () => {
    setSelectedLocation(null);
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={mapCenter}
      zoom={options.zoom}
    >
      <Marker
        position={mapCenter}
        icon={customIcon}
        onClick={handleMarkerClick}
      />
      {/* <Circle center={mapCenter} radius={200} options={{ fillColor: 'red', strokeColor: 'red' }} onClick={handleMarkerClick} /> */}
      <Circle
        center={mapCenter}
        radius={1000}
        options={{
          fillColor: "red",
          fillOpacity: 0.6,
          strokeColor: "red",
          strokeOpacity: 0.8,
          strokeWeight: 2,
        }}
        onClick={handleMarkerClick} 
      />
      {/* {selectedLocation && (
        <InfoWindow position={selectedLocation} onCloseClick={handleCloseClick}>
          <div>
            Latitude: {selectedLocation.lat.toFixed(4)}, Longitude: {selectedLocation.lng.toFixed(4)}
          </div>
        </InfoWindow>
      )} */}
    </GoogleMap>
  );
};

export default MapView;
