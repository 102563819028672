import React, { useState, useEffect, navigate } from "react";
import Footer from "../footer/footer.js";
import NavbarHome from "../pages/common/Navbar.js";
import { useNavigate } from "react-router-dom";
import {ToastContainer } from "react-toastify";
import "../../assets/css/header.css";
import Discover from "../pages/common/Discover.js";
import Recomandation from "../pages/common/Recomandation.js";
import Themes from "./Themes.js";
import FeatureTour from "./FeatureTour.js";
import Section from "./Section.js";
import SpecialTours from "./SpecialTours.js";
import SpecialTourBmm from "./SpecialTourBmm.js";
import ExpeditionInfo from "../pages/common/ExpeditionInfo .js";
import { useAuth } from "../../context/Authcontext.js";
import Search from "../pages/search/Search.js";

const Header = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const specialToursStyle = {
    marginTop: user ? "40px" : "0",
  };

  const isMobile = window.innerWidth <= 768;

  const searchToursStyle = {
    marginTop: user ? (isMobile ? "30px" : "80px") : "0",
  };

  return (
    <>
    <div>
      <div>
        <NavbarHome />
      </div>
      {!user && <Section />}
      <div style={searchToursStyle}>
        <Search />
      </div>{" "}
      <div style={specialToursStyle}>
        <SpecialTours />
      </div>{" "}
      <SpecialTourBmm />
      <Themes />
      <Discover />
      <FeatureTour />
      <ExpeditionInfo />
      <Recomandation />
      <Footer />
      <ToastContainer />
    </div>
    </>
  );
};

export default Header;
