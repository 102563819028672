import React, { useEffect, useState } from "react";
import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";

import "../../../assets/css/tourpage.css";
import { useNavigate } from "react-router-dom";
import defaultImg from "../../../assets/image/9b.jpg";
import starImg from "../../../assets/image/Star 13.png";
import { toast } from "react-toastify";

import {
  ArrowLeft,
  ArrowRight,
  Bookmark,
  BookmarkFill,
  GeoAlt,
} from "react-bootstrap-icons";

const SuggestTour = ({ suggestedTourData }) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

  console.log("suggestedTourData===>", suggestedTourData);

  useEffect(() => {
    window.scrollTo(0, 0);
    setDisplayedTours(suggestedTourData.slice(0, 4));
  }, [suggestedTourData]);
  const userData = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(new Set());
  const [displayedTours, setDisplayedTours] = useState([]);

  const handleTourCardClick = (tour) => {
    const tourId = tour.tourId;
    navigate(`/tour/${tourId}`, {
      state: {
        tourId,
      },
    });
  };

  const addToWishlist = async (tourId) => {
    if (!userData?.userId) {
       toast.error("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/save/favTour`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Failed to add to wishlist: ${response.statusText}`);
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => new Set(prevWishlist).add(tourId));
        toast.success("Added to your Wishlist");
      }
    } catch (error) {
       toast.error(`Error: ${error.message}`);
    }
  };

  const removeFromWishlist = async (tourId) => {
    if (!userData?.userId) {
       toast.error("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/delete/fav-tour`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove from wishlist: ${response.statusText}`
        );
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => {
          const newWishlist = new Set(prevWishlist);
          newWishlist.delete(tourId);
          return new Set(newWishlist);
        });
        toast.success("Successfully removed from wishlist!");
      }
    } catch (error) {
       toast.error(`Error: ${error.message}`);
    }
  };

  const directionButtons = (direction) => {
    return (
      <span>
        {direction === "Next" ? (
          <ArrowRight className="rightArrow" />
        ) : (
          <ArrowLeft className="rightArrow" />
        )}
      </span>
    );
  };

  const truncateAddress = (address) => {
    if (!address) return "Address not available";

    const words = address.split(" ");
    if (words.length > 4) {
      return words.slice(0, 4).join(" ") + "...";
    }
    return address;
  };

  const handleTourLocationClick = (tour) => {
    if (tour.location?.latitude && tour.location?.longitude) {
      const googleMapsUrl = `https://www.google.com/maps?q=${tour.location.latitude},${tour.location.longitude}`;
      window.open(googleMapsUrl, "_blank");
    } else {
       toast.error("Location data is not available for this tour");
    }
  };

  const handleViewMore = () => {
    const nextTours = suggestedTourData.slice(displayedTours.length, displayedTours.length + 4);
    setDisplayedTours((prev) => [...prev, ...nextTours]);
  };

  const hasMoreTours = displayedTours.length < suggestedTourData.length;

  return (
    <>
      <Container fluid className="px-5 py-4 custom-container">
        <Row>
          <Col xs={12} sm={6} md={4} lg={3}>
            <h1 className="text-start feature-heading mt-5">Suggested Tours</h1>
          </Col>
        </Row>
        <Row>
        {suggestedTourData.length > 0 ? (
          displayedTours.map((tour) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={tour.tourId}
                className="mb-4"
              >
                <Card className="h-100">
                  <div className="carousel-hover">
                    <Carousel
                      className="carousel-overlay"
                      interval={null}
                      nextIcon={directionButtons("Next")}
                      prevIcon={directionButtons("Previous")}
                    >
                      {tour.tourAssets.map((asset, assetIdx) => (
                        <Carousel.Item
                          key={assetIdx}
                          className="card-img-container"
                        >
                          <img
                            onClick={() => handleTourCardClick(tour)}
                            className=" "
                            src={tour.tourAssets[0]?.assetUrl || defaultImg}
                            alt={`Slide ${assetIdx}`}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <img
                      onClick={() => handleTourCardClick(tour)}
                      variant="top"
                      src={tour.tourAssets[0]?.assetUrl || defaultImg}
                      className=" card-img-container"
                    />
                    <div className="bookmark-icon">
                      {wishlist.has(tour.tourId) ? (
                        <BookmarkFill
                          onClick={() => removeFromWishlist(tour.tourId)}
                          style={{ color: "white" }}
                        />
                      ) : (
                        <Bookmark onClick={() => addToWishlist(tour.tourId)} />
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-column mt-2">
                    <span
                      className="font-medium-title feature-title"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleTourCardClick(tour)}
                    >
                      {tour.title}
                    </span>
                    <Row>
                      <div>
                        <div className="d-inline z-30 font-feature-tour">
                          {!tour.discount && tour.schedules?.length > 0 && (
                            <span>
                              From{" "}
                              <span className="me-1">
                                {tour.schedules[0].currency || tour.currency}
                                &nbsp;
                              </span>
                              {tour.schedules[0].price || tour.price}
                            </span>
                          )}
                          {tour.discount && (
                            <div>
                              From{" "}
                              <span className="text-decoration-line-through">
                                {tour.schedules[0].currency || tour.currency}
                                &nbsp;{tour.price}
                              </span>
                              <span className="ms-2 discount-price">
                                {tour.schedules[0].currency || tour.currency}
                                &nbsp;
                                {Math.round(
                                  tour.price * (1 - tour.discount / 100)
                                )}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="float-end text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                          Duration {tour.duration ? tour.duration : 4}{" "}
                          {tour.durationTime ? tour.durationTime : "days"}
                        </div>
                      </div>
                    </Row>
                    <Row className="mb-5">
                      <div className="flex justify-between gap-3 mt-auto col-12 ">
                        <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4">
                          <span className="black-star">
                            <img className="starImg" src={starImg} alt="star" />
                          </span>
                          <span className="ms-2">
                            {tour.rating ? tour.rating : "4.0"}
                          </span>
                          <span className="dot"></span>
                          <span className="text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                            Ratings
                          </span>
                        </div>

                        <div
                          role="button"
                          className="float-end col-lg-5 col-6 font-medium location-text text-wrap"
                          onClick={() => handleTourLocationClick(tour)}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                          }}
                        >
                          <span className="font-medium text-nowrap">
                            <GeoAlt className="text-muted me-1" />
                            {/* {truncateAddress(tour.formattedAddress)} */}
                            {truncateAddress(tour.location?.address)}

                          </span>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Card>
              </Col>
            ))
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <Button className="coming-soon-btn">
                {" "}
                <span className="fw-bold font-common">
                  Tours will be coming soon...
                </span>{" "}
              </Button>
            </div>
          )}
        </Row>

        {hasMoreTours && (
          <div className="text-end mb-2">
            <Button className="view-more-btn" onClick={handleViewMore}>
              Load More Activities
            </Button>
          </div>
        )}
      </Container>
    </>
  );
};

export default SuggestTour;
