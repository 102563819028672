import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Card, Carousel, Col, Container, Row } from "react-bootstrap";
import Navbar from "../common/Navbar";
import { useTour } from "../../../context/TourContext";
import Recomandation from "../common/Recomandation";
import Footer from "../../footer/footer";
import Discover from "../common/Discover";
import "../../../assets/css/tourpage.css";
import {
  ArrowLeft,
  ArrowRight,
  Bookmark,
  BookmarkFill,
  GeoAlt,
} from "react-bootstrap-icons";
import starImg from "../../../assets/image/Star 13.png";
import { toast } from "react-toastify";
import axios from "axios";
import defaultImg from "../../../assets/image/9b.jpg";
import { IoChevronForward } from "react-icons/io5";

const TourPage = () => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));

  const {
    selectedCategory,
    setSelectedCategory,
    selectedSubCategory,
    setSelectedSubCategory,
  } = useTour();

  const [tourData, setTourData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedThemeName, setSelectedThemeName] = useState("");
  const location = useLocation();
  const { themeId, pageName } = location.state || {};
  const [displayedTours, setDisplayedTours] = useState([]);
  const [showAllTours, setShowAllTours] = useState(false);
  const [wishlist, setWishlist] = useState(new Set());

  console.log("themeid==>", themeId);
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTourData();
    fetchThemeData();
  }, [selectedCategory, selectedSubCategory]);


  const fetchTourData = async () => {
    if (!selectedCategory || !selectedSubCategory) return;
    try {
      const response = await fetch(
        `${BaseAPI}/tour/theme/${themeId}/category/${selectedCategory}/subcategory/${selectedSubCategory}`
      );
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      console.log("data", data.data);
  
      const toursWithLocation = await Promise.all(
        data.data
          .filter((tour) => tour.status === 1)          
      );
  
      const rearrangedData = toursWithLocation.map((item) => ({
        ...item,
        tourAssets: [
          ...item.tourAssets.filter((image) => image.assetCategory === "FEATURED"),
          ...item.tourAssets.filter((image) => image.assetCategory !== "FEATURED"),
        ],
      }));
  
      setTourData(rearrangedData);
    } catch (error) {
      console.error("Error fetching tour data:", error);
    }
  };
  


  useEffect(() => {
    fetch(`${BaseAPI}/tour/all/categories`)
      .then((response) => response.json())
      .then((data) => setCategories(data.data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetch(`${BaseAPI}/tour/subcategory/category/${selectedCategory}`)
        .then((response) => response.json())
        .then((data) => setSubcategories(data.data))
        .catch((error) =>
          console.error("Error fetching subcategories:", error)
        );
    }
  }, [selectedCategory]);

  const fetchThemeData = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/themes`);
      const data = await response.json();
      if (data.statusCode === 200) {
        const defaultTheme = data.data.find(
          (theme) => theme.themeName === "Adventure"
        );
        if (defaultTheme) {
          setSelectedThemeName(defaultTheme.themeName);
        }
      } else {
        console.error("Failed to fetch themes. Status:", data.statusMessage);
      }
    } catch (error) {
      console.error("Error fetching themes:", error);
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubCategory(null);
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
  };

  const handleTourCardClick = (tour) => {
    const tourId = tour.tourId;
    navigate(`/tour/${tourId}`, {
      state: {
        tourId,
        pageName: pageName,
        selectedCategory: selectedCategory,
        selectedSubCategory: selectedSubCategory,
        selectedThemeName: selectedThemeName,
      },
    });
  };

  const handleTourLocationClick = (tour) => {
    if (tour.location?.latitude && tour.location?.longitude) {
      const googleMapsUrl = `https://www.google.com/maps?q=${tour.location.latitude},${tour.location.longitude}`;
      window.open(googleMapsUrl, "_blank");
    } else {
       toast.error("Location data is not available for this tour");
    }
  };

  const handleViewMore = () => {
    const nextTours = tourData?.slice(
      displayedTours?.length,
      displayedTours?.length + 4
    );
    setDisplayedTours([...displayedTours, ...nextTours]);
    setShowAllTours(
      displayedTours?.length + nextTours?.length >= tourData?.length
    );
  };

  useEffect(() => {
    if (tourData?.length > 0) {
      setDisplayedTours(tourData?.slice(0, Math.min(4, tourData?.length)));
      setShowAllTours(tourData?.length <= 4);
    }
  }, [tourData]);

  const addToWishlist = async (tourId) => {
    if (!userData?.userId) {
       toast.error("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/save/favTour`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Failed to add to wishlist: ${response.statusText}`);
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => new Set(prevWishlist).add(tourId));
        toast.success("Added to your Wishlist");
      }
    } catch (error) {
       toast.error(`Error: ${error.message}`);
    }
  };

  const removeFromWishlist = async (tourId) => {
    if (!userData?.userId) {
       toast.error("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/delete/fav-tour`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove from wishlist: ${response.statusText}`
        );
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => {
          const newWishlist = new Set(prevWishlist);
          newWishlist.delete(tourId);
          return new Set(newWishlist);
        });
        toast.success("Successfully removed from wishlist!");
      }
    } catch (error) {
       toast.error(`Error: ${error.message}`);
    }
  };

  const directionButtons = (direction) => {
    return (
      <span>
        {direction === "Next" ? (
          <ArrowRight className="rightArrow" />
        ) : (
          <ArrowLeft className="rightArrow" />
        )}
      </span>
    );
  };

 

  const truncateAddress = (address) => {
    const words = address.split(" ");
    if (words.length > 4) {
      return words.slice(0, 4).join(" ") + "...";
    }
    return address;
  };

  return (
    <>
      <Navbar pageName={pageName} />
      <div>
        <Container fluid className="px-5 mb-5 mt-2 custom-container">
         

          <div className=" mb-1 d-flex align-items-center flex-wrap tour-category">
            <span className="category-select font-title-medium">
              {selectedThemeName}
            </span>
            <IoChevronForward />
            <select
              className="category-select font-title-medium"
              value={selectedCategory}
              onChange={handleCategoryChange}
            >
              <option value="">Select Category</option>
              {categories?.map((cat) => (
                <option key={cat.categoryId} value={cat.categoryId}>
                  {cat.categoryName}
                </option>
              ))}
            </select>
            <IoChevronForward />
            <select
              className="Subcategory-select ms-2 font-title-medium"
              value={selectedSubCategory}
              onChange={handleSubcategoryChange}
              disabled={!selectedCategory}
            >
              <option value="">Select Subcategory</option>
              {subcategories?.map((sub) => (
                <option key={sub.subCategoryId} value={sub.subCategoryId}>
                  {sub.subCategoryName}
                </option>
              ))}
            </select>
          </div>

          <div className="tour-container">
            {tourData && tourData.length > 0 ? (
              <Row>
                {displayedTours.map((tour) => (
                  <Col xs={12} sm={6} md={4} lg={3} key={tour.tourId}>
                    <Card className="h-100">
                      <div className="carousel-hover">
                        <Carousel
                          className="carousel-overlay"
                          interval={null}
                          nextIcon={directionButtons("Next")}
                          prevIcon={directionButtons("Previous")}
                        >
                          {tour.tourAssets?.length > 0 ? (
                            tour.tourAssets?.map((asset, assetIdx) => (
                              <Carousel.Item
                                key={assetIdx}
                                className="card-img-container"
                              >
                                <img
                                  onClick={() => handleTourCardClick(tour)}
                                  className=" "
                                  src={asset.assetUrl || defaultImg}
                                  alt={`Slide ${assetIdx}`}
                                />
                              </Carousel.Item>
                            ))
                          ) : (
                            <Carousel.Item className="card-img-container">
                              <img
                                onClick={() => handleTourCardClick(tour)}
                                className=" "
                                src={defaultImg}
                                alt="Default Tour"
                              />
                            </Carousel.Item>
                          )}
                        </Carousel>
                        <img
                          onClick={() => handleTourCardClick(tour)}
                          variant="top"
                          src={tour.tourAssets?.[0]?.assetUrl || defaultImg}
                          className="card-img-container"
                        />
                        <div className="bookmark-icon">
                          {wishlist.has(tour.tourId) ? (
                            <BookmarkFill
                              onClick={() => removeFromWishlist(tour.tourId)}
                              style={{ color: "white" }}
                            />
                          ) : (
                            <Bookmark
                              onClick={() => addToWishlist(tour.tourId)}
                            />
                          )}
                        </div>
                      </div>

                      <div className="d-flex flex-column mt-2">
                        <span
                          className="font-medium-title feature-title"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleTourCardClick(tour)}
                        >
                          {tour.title}
                        </span>
                        <Row>
                          <div>
                            <div className="d-inline z-30 font-feature-tour">
                              {!tour.discount && tour.schedules?.length > 0 && (
                                <span>
                                  From{" "}
                                  <span className="me-1">
                                    {tour.schedules[0].currency ||
                                      tour.currency}
                                    &nbsp;
                                  </span>
                                  {tour.schedules[0].price || tour.price}
                                </span>
                              )}
                              {tour.discount && (
                                <div>
                                  From{" "}
                                  <span className="text-decoration-line-through">
                                    {tour.schedules[0].currency ||
                                      tour.currency}
                                    &nbsp;{tour.price}
                                  </span>
                                  <span className="ms-2 discount-price">
                                    {tour.schedules[0].currency ||
                                      tour.currency}
                                    &nbsp;
                                    {Math.round(
                                      tour.price * (1 - tour.discount / 100)
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="float-end text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                              Duration {" "}
                        {tour.duration ? `${tour.duration} days` : '4 days'}
                              {/* {tour.durationTime ? tour.durationTime : "days"} */}
                            </div>
                          </div>
                        </Row>
                        <Row className="mb-5">
                          <div className="flex justify-between gap-3 mt-auto col-12">
                            <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4">
                              <span className="black-star">
                                <img
                                  className="starImg"
                                  src={starImg}
                                  alt="star"
                                />
                              </span>
                              <span className="ms-2">5.0</span>
                              <span className="dot"></span>
                              <span className="text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                                3 Ratings
                              </span>
                            </div>

                            <div
                              role="button"
                              className="float-end col-lg-5 col-6 font-medium location-text text-wrap"
                              onClick={() => handleTourLocationClick(tour)}
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                              }}
                            >
                              <span className="font-medium text-nowrap">
                                <GeoAlt className="text-muted me-1" />
                                {/* {tour.formattedAddress
                                  ? truncateAddress(tour.formattedAddress)
                                  : "Address not available"} */}
                                {truncateAddress(tour.location?.address)}

                              </span>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <Button className="coming-soon-btn">
                  {" "}
                  <span className="fw-bold font-common">
                    Tours will be coming soon...
                  </span>{" "}
                </Button>
              </div>
            )}
          </div>

          {tourData?.length > 4 && !showAllTours && (
            <div className="d-flex justify-content-end me-5 mb-2">
              <Button className="view-more-btn" onClick={handleViewMore}>
                Load More Activities
              </Button>
            </div>
          )}
        </Container>
      </div>
      <Discover />
      <Recomandation />
      <Footer />
    </>
  );
};

export default TourPage;
