import React, { useState, useEffect } from "react";
import {ArrowLeft, ArrowRight,Bookmark, BookmarkFill, GeoAlt,} from "react-bootstrap-icons";
import { Container, Row, Col, Card,Carousel,Button, Form,FormControl,} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/Authcontext";
import starImg from "../../../assets/image/Star 13.png";
import defaultImg from "../../../assets/image/9b.jpg";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import '../../../assets/css/navbar.css';

const Search = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const navigate = useNavigate();

  const { user } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wishlist, setWishlist] = useState(new Set());
  const [searchSubmitted, setSearchSubmitted] = useState(false); 

  
  useEffect(() => {
    if (!searchQuery.trim()) {
      setSearchResults([]);
      setSearchSubmitted(false); 
    }
  }, [searchQuery]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) {
      setSearchResults([]);
      setSearchSubmitted(false);
      return;
    }
    setLoading(true);
    setSearchSubmitted(true);
    try {
      const response = await axios.get(`${BaseAPI}/tour/search?param=${searchQuery}`);
      
      const toursWithRearrangedAssets = response.data.data.map((tour) => ({
        ...tour,
        tourAssets: [
          ...tour.tourAssets.filter((image) => image.assetCategory === "FEATURED"),
          ...tour.tourAssets.filter((image) => image.assetCategory !== "FEATURED"),
        ],
      }));
  
      setSearchResults(toursWithRearrangedAssets);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching search results:", error);
      toast.error("Failed to fetch search results. Please try again.");
      setLoading(false);
    }
  };
  

  const handleTourCardClick = (tour) => {
    const tourId = tour.tourId;
    navigate(`/tour/${tourId}`, {
      state: {
        tourId,
      },
    });
  };

  const directionButtons = (direction) => {
    return (
      <span>
        {direction === "Next" ? (
          <ArrowRight className="rightArrow" />
        ) : (
          <ArrowLeft className="rightArrow" />
        )}
      </span>
    );
  };

  const handleTourLocationClick = (tour) => {
    if (tour.location?.latitude && tour.location?.longitude) {
      const googleMapsUrl = `https://www.google.com/maps?q=${tour.location.latitude},${tour.location.longitude}`;
      window.open(googleMapsUrl, "_blank");
    } else {
       toast.error("Location data is not available for this tour");
    }
  };

  const truncateAddress = (address) => {
    if (!address) {
      return "Address not available";
    }

    const words = address.split(" ");
    if (words.length > 4) {
      return words.slice(0, 4).join(" ") + "...";
    }
    return address;
  };

  const addToWishlist = async (tourId) => {
    if (!userData?.userId) {
       toast.error("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/save/favTour`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Failed to add to wishlist: ${response.statusText}`);
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => new Set(prevWishlist).add(tourId));
        toast.success("Added to your Wishlist");
      }
    } catch (error) {
       toast.error(`Error: ${error.message}`);
    }
  };

  const removeFromWishlist = async (tourId) => {
    if (!userData?.userId) {
       toast.error("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/delete/fav-tour`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove from wishlist: ${response.statusText}`
        );
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => {
          const newWishlist = new Set(prevWishlist);
          newWishlist.delete(tourId);
          return new Set(newWishlist);
        });
        toast.success("Successfully removed from wishlist!");
      }
    } catch (error) {
       toast.error(`Error: ${error.message}`);
    }
  };

  return (
    <>
      <Container fluid className="custom-container h-auto mt-4 px-5">
        <div>
          <Form
            className="d-flex mb-3 justify-content-center align-content-center mt-5 mb-4"
            onSubmit={handleSearch}
          >
            <FormControl
              type="text"
              placeholder="Search tours by location or name"
              className="me-2 search-boxs"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button variant="" type="submit" className=" build-trip-button">
              <span className="trip-element text-wrap">Search</span>
            </Button>
          </Form>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : searchResults && searchResults.length > 0 ? (
          <>
            <h1 className="text-start feature-heading">Search Results</h1>

            <Row>
              {searchResults.map((tour, idx) => (
                <Col xs={12} sm={6} md={4} lg={3} key={idx}>
                  <Card className="h-100">
                    <div>
                      <div className="carousel-hover">
                        <Carousel
                          className="carousel-overlay"
                          interval={null}
                          nextIcon={directionButtons("Next")}
                          prevIcon={directionButtons("Previous")}
                        >
                          {tour.tourAssets.length > 0 ? (
                            tour.tourAssets.map((asset, assetIdx) => (
                              <Carousel.Item
                                key={assetIdx}
                                className="card-img-container"
                              >
                                <img
                                  onClick={() => handleTourCardClick(tour)}
                                  className=" "
                                  src={asset?.assetUrl || defaultImg}
                                  alt={`Slide ${assetIdx}`}
                                />
                              </Carousel.Item>
                            ))
                          ) : (
                            <Carousel.Item className="card-img-container">
                              <img
                                onClick={() => handleTourCardClick(tour)}
                                className=" "
                                src={defaultImg}
                                alt="Default Tour"
                              />
                            </Carousel.Item>
                          )}
                        </Carousel>
                        <img
                          onClick={() => handleTourCardClick(tour)}
                          variant="top"
                          src={
                            tour.tourAssets && tour.tourAssets.length > 0
                              ? tour.tourAssets[0]?.assetUrl
                              : defaultImg
                          }
                          className="card-img-container"
                          alt={tour.title}
                        />

                        <div className="overlay-text">
                          {tour.tourTheme[0].theme.themeName}
                        </div>

                        {tour.discount && (
                          <div className="discount-badge">
                            {tour.discount} OFF
                          </div>
                        )}
                        <div className="bookmark-icon">
                          {wishlist.has(tour.tourId) ? (
                            <BookmarkFill
                              onClick={() => removeFromWishlist(tour.tourId)}
                              style={{ color: "white" }}
                            />
                          ) : (
                            <Bookmark
                              onClick={() => addToWishlist(tour.tourId)}
                            />
                          )}
                        </div>
                      </div>

                      <div className="d-flex flex-column mt-2">
                        <span
                          className="font-medium-title feature-title "
                          style={{ cursor: "pointer" }}
                          onClick={() => handleTourCardClick(tour)}
                        >
                          {tour.title}
                        </span>
                        <Row>
                          <div>
                            <div className="d-inline z-30 font-feature-tour">
                              {!tour.discount && tour.schedules?.length > 0 && (
                                <span>
                                  From{" "}
                                  <span className="me-1">
                                    {tour.schedules[0].currency ||
                                      tour.currency}
                                    &nbsp;
                                  </span>
                                  {tour.schedules[0].price || tour.price}
                                </span>
                              )}
                              {tour.discount && (
                                <div>
                                  From{" "}
                                  <span className="text-decoration-line-through">
                                    {tour.schedules[0].currency ||
                                      tour.currency}
                                    &nbsp;{tour.price}
                                  </span>
                                  <span className="ms-2 discount-price">
                                    {tour.schedules[0].currency ||
                                      tour.currency}
                                    &nbsp;
                                    {Math.round(
                                      tour.price * (1 - tour.discount / 100)
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className="float-end text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                              Duration {tour.duration ? tour.duration : 4}{" "}
                              {tour.durationTime ? tour.durationTime : "days"}
                            </div>
                          </div>
                        </Row>

                        <Row className="mb-5">
                          <div className="flex justify-between gap-3 mt-auto col-12 ">
                            <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4">
                              <span className="black-star">
                                <img
                                  className="starImg"
                                  src={starImg}
                                  alt="star"
                                />
                              </span>
                              <span className="ms-2">
                                {tour.rating ? tour.rating : "4.0"}
                              </span>

                              <span className="dot"></span>
                              <span className="text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                                Ratings
                              </span>
                            </div>

                            <div
                              role="button"
                              className="float-end col-lg-5 col-6 font-medium location-text text-wrap"
                              onClick={() => handleTourLocationClick(tour)}
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                              }}
                            >
                              <span className="font-medium text-nowrap">
                                <GeoAlt className="text-muted me-1" />
                                {truncateAddress(tour.location?.address)}
                              </span>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          searchSubmitted && !loading && searchQuery && (
            <p>No tours available. Please try searching again.</p>
          )
        )}
      </Container>
    </>
  );
};

export default Search;
