import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Nav, Card, Button, Col, Row } from "react-bootstrap";
import { useTour } from "../../../context/TourContext";
import "../../../assets/css/adventure.css";
import Navbar from "../common/Navbar";
import Recomandation from "../common/Recomandation";
import Footer from "../../footer/footer";
import Discover from "../common/Discover";
import landImg from "../../../assets/image/land.png";
import waterImg from "../../../assets/image/drops.png";
import skyImg from "../../../assets/image/clear-sky.png";

const Adventure = () => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const navigate = useNavigate();
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [showCategories, setShowCategories] = useState([]);
  const [showSubCategories, setShowSubCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedThemeId, setSelectedThemeId] = useState("");
  const [visibleSubCategories, setVisibleSubCategories] = useState(8);
  const location = useLocation();
  const { categoryId } = location.state || {};
  const { setSelectedCategory, setSelectedSubCategory } = useTour();

  const [themeInfo, setThemeInfo] = useState({
    themeName: "",
    themeDesc: "",
    themeId: "",
  });

  useEffect(() => {
    if (categoryId) {
      setActiveCategoryId(categoryId);
      fetchSubCategories(categoryId);
    }
    fetchCategories();
    fetchThemeData();
    window.scrollTo(0, 0);
  }, [categoryId]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/categories`);
      const data = await response.json();
      setShowCategories(data.data);

      if (data.data.length > 0 && !categoryId) {
        const defaultCategoryId = data.data[0].categoryId;
        setActiveCategoryId(defaultCategoryId);
        fetchSubCategories(defaultCategoryId);
        setSelectedThemeId(data.data[0].theme.themeId);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubCategories = async (categoryId) => {
    try {
      const response = await fetch(
        `${BaseAPI}/tour/subcategory/category/${categoryId}`
      );
      const data = await response.json();
      setShowSubCategories(data.data);
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryClick = (categoryId, categoryName) => {
    if (categoryId !== activeCategoryId) {
      setActiveCategoryId(categoryId);
      fetchSubCategories(categoryId);
      setVisibleSubCategories(8);
    }
  };

  const fetchThemeData = async () => {
    try {
      const response = await fetch(`${BaseAPI}/tour/all/themes`);
      const data = await response.json();
      if (data.statusCode === 200) {
        const defaultTheme = data.data.find(
          (theme) => theme.themeName === "Adventure"
        );
        if (defaultTheme) {
          setThemeInfo({
            themeName: defaultTheme.themeName,
            themeDesc: defaultTheme.themeDesc,
            themeId: defaultTheme.themeId,
          });
        }
      } else {
        console.error("Failed to fetch themes. Status:", data.statusMessage);
      }
    } catch (error) {
      console.error("Error fetching themes:", error);
    }
  };

  const handleSubCategoryClick = (
    subCategoryId,
    categoryId,
    subCategoryName
  ) => {
    setSelectedCategory(categoryId);
    setSelectedSubCategory(subCategoryId);
    navigate("/tourpage", {
      state: { themeId: themeInfo.themeId, pageName: "/adventure" },
    });
  };

  const handleViewMore = () => {
    setVisibleSubCategories((prevVisible) => prevVisible + 8);
  };

  useEffect(() => {
    if (categoryId) {
      setActiveCategoryId(categoryId);
      fetchSubCategories(categoryId); 
    }
    fetchCategories();
    fetchThemeData();
    window.scrollTo(0, 0);
  }, [categoryId]); 
  

  return (
    <>
      <Navbar />

      <div>
        <Row>
          <Col
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className="position-relative text-white w-100 "
          >
            <div style={{ zIndex: "5" }} className="fixed-top adventure-dropdown border-navbar px-4 font-medium-title set-bar-adv">
              <Nav className="navbar-category">
                {showCategories?.map((category) => (
                  <Nav.Link
                    key={category.categoryId}
                    onClick={() => handleCategoryClick(category.categoryId)}
                    className={`${
                      category.categoryId === activeCategoryId
                        ? "active-category"
                        : ""
                    } category-link`}
                    style={{ marginRight: "35px" }}
                  >
                    {category.categoryName === "Land" && (
                      <span className="ms-2">
                        {" "}
                        <img className="category-icon" src={landImg} alt="" />
                      </span>
                    )}
                    {category.categoryName === "Water" && (
                      <span className="ms-2">
                        {" "}
                        <img className="category-icon" src={waterImg} alt="" />
                      </span>
                    )}
                    {category.categoryName === "Sky" && (
                      <span className="ms-2">
                        {" "}
                        <img className="category-icon" src={skyImg} alt="" />
                      </span>
                    )}
                    <span className=" font-medium-title">
                      {category.categoryName}
                    </span>
                  </Nav.Link>
                ))}
              </Nav>
            </div>
          </Col>
        </Row>
      </div>

      <div className="my-5 mx-2  main-container-adventure custom-container">
        <div className="row mx-4  pt-5 pb-2 adventure-main-responsive custom-row">
          {/* <h2 className="mb-2 mt-5 display-4 font-schedule-title">
            {themeInfo.themeName}
          </h2> */}
          <p className="text-gray-700 dark:text-gray-400 font-feature-tour py-2 mt-5 custom-paragraph">
            {/* {themeInfo.themeDesc} */}
            Explore the Wild Embrace the thrill of nature with adventures that
            inspire exploration and connect you to the earth’s raw beauty.
          </p>
        </div>

        <div className="row my-2 ms-4 me-4 adventure-images">
          {showSubCategories
            ?.slice(0, visibleSubCategories)
            .map((subCategory) => (
              <div
                className="col-12 col-sm-6 col-md-4 col-lg-3"
                key={subCategory.subCategoryId}
                onClick={() =>
                  handleSubCategoryClick(
                    subCategory.subCategoryId,
                    activeCategoryId,
                    subCategory.subCategoryName
                  )
                }
              >
                <Card className="mb-3 adventure-main-card ">
                  <div className="card-img-container">
                    <Card.Img
                      variant="top"
                      src={subCategory.imageUrl}
                      className="img-fluid "
                    />
                    <div className="overlay-text">
                      {subCategory.subCategoryName}
                    </div>
                  </div>
                </Card>
              </div>
            ))}
        </div>

        {visibleSubCategories < showSubCategories?.length && (
          <div className="d-flex justify-content-end me-5 mb-2">
            <Button onClick={handleViewMore}>View More</Button>
          </div>
        )}
      </div>

      <Discover />
      <Recomandation />
      <Footer />
    </>
  );
};

export default (props) => {
  const location = useLocation();
  return <Adventure key={location.state?.categoryId} {...props} />;
};
