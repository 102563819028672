import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Carousel, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "../../../assets/css/featuretour.css";
import { useNavigate } from "react-router-dom";
import {
  ArrowBarRight,
  Bookmark,
  GeoAlt,
  ArrowLeft,
  ArrowRight,
  BookmarkFill,
} from "react-bootstrap-icons";
import NavbarHome from "../common/Navbar.js";

import axios from "axios";
import starImg from "../../../assets/image/Star 13.png";

const MyWhishlist = () => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const BaseAPIAuth = process.env.REACT_APP_AUTH_SERVER_ENDPOINT;
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("user"));

  const [featureTours, setFeatureTours] = useState([]);
  const [wishLists, setWishlists] = useState([]);
  const [displayedTours, setDisplayedTours] = useState([]);
  const [wishlist, setWishlist] = useState(new Set());

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const response = await fetch(
          `${BaseAPI}/tour/favTours/user/${userData.userId}`
        );

        if (response.ok) {
          const data = await response.json();
          const toursData = await Promise.all(
            data.data.filter((tour) => tour.status === 1)
          );

          setFeatureTours(toursData);
          updateWishlist(toursData);
        } else {
          console.error("Failed to fetch themes:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchTours();
  }, []);

  const updateWishlist = (tours) => {
    const newWishlist = new Set(wishlist);
    tours.forEach((tour) => {
      if (tour.isFavourite) {
        newWishlist.add(tour.tourId);
      }
    });
    setWishlist(newWishlist);
  };

  useEffect(() => {}, []);

  const addToWishlist = async (tourId) => {
    if (!userData?.userId) {
      toast.error("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/save/favTour`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Failed to add to wishlist: ${response.statusText}`);
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => new Set(prevWishlist).add(tourId));
        toast.success("Added to your Wishlist");
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const removeFromWishlist = async (tourId) => {
    if (!userData?.userId) {
      toast.error("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/delete/fav-tour`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove from wishlist: ${response.statusText}`
        );
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist((prevWishlist) => {
          const newWishlist = new Set(prevWishlist);
          newWishlist.delete(tourId);
          return new Set(newWishlist);
        });
        toast.success("Successfully removed from wishlist!");
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleViewMore = () => {
    const nextTours = featureTours?.slice(
      displayedTours?.length,
      displayedTours?.length + 4
    );
    setDisplayedTours([...displayedTours, ...nextTours]);
  };

  const hasMoreTours = displayedTours?.length < featureTours?.length;

  const directionButtons = (direction) => {
    return (
      <span>
        {direction === "Next" ? (
          <ArrowRight className="rightArrow" />
        ) : (
          <ArrowLeft className="rightArrow" />
        )}
      </span>
    );
  };

  useEffect(() => {
    if (featureTours?.length > 0) {
      setDisplayedTours(
        featureTours?.slice(0, Math.min(4, featureTours?.length))
      );
    }
  }, [featureTours]);

  const handleTourCardClick = (tour) => {
    const tourId = tour.tourId;
    navigate(`/tour/${tourId}`, {
      state: {
        tourId,
      },
    });
  };

  const truncateAddress = (address) => {
    const words = address.split(" ");
    if (words.length > 4) {
      return words.slice(0, 4).join(" ") + "...";
    }
    return address;
  };

  return (
    <>
      <div>
        <NavbarHome />
      </div>
      <Container
        fluid
        className="custom-container h-auto  px-5 "
        style={{ marginTop: "70px" }}
      >
        <h1 className="text-start feature-heading"> My Wishlist </h1>
        <Row>
          {displayedTours.map((tour, idx) => (
            <Col xs={12} sm={6} md={4} lg={3} key={idx}>
              <Card className="h-100">
                <div>
                  <div className="carousel-hover">
                    <Carousel
                      className="carousel-overlay"
                      interval={null}
                      nextIcon={directionButtons("Next")}
                      prevIcon={directionButtons("Previous")}
                    >
                      {tour.tourAssets.map((asset, assetIdx) => (
                        <Carousel.Item
                          key={assetIdx}
                          className="card-img-container"
                        >
                          <img
                            onClick={() => handleTourCardClick(tour)}
                            className=" "
                            src={asset.assetUrl}
                            alt={`Slide ${assetIdx}`}
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <img
                      onClick={() => handleTourCardClick(tour)}
                      variant="top"
                      src={tour.tourAssets[0].assetUrl}
                      className=" card-img-container"
                    />
                    <div className="overlay-text">
                      {tour.tourTheme[0].theme.themeName}
                    </div>
                    <div className="bookmark-icon">
                      {wishlist.has(tour.tourId) ? (
                        <BookmarkFill
                          onClick={() => removeFromWishlist(tour.tourId)}
                          style={{ color: "white" }}
                        />
                      ) : (
                        <Bookmark onClick={() => addToWishlist(tour.tourId)} />
                      )}
                    </div>
                  </div>

                  <div className="d-flex flex-column mt-2">
                    <span className="font-medium-title feature-title">
                      {tour.title}
                    </span>
                    <Row>
                      <div>
                      <div className="d-inline z-30 font-feature-tour">
                        {!tour.discount && tour.schedules?.length > 0 && (
                          <span>
                            From{" "}
                            <span className="me-1">
                              {tour.schedules[0].currency || tour.currency}
                              &nbsp;
                            </span>
                            {tour.schedules[0].price || tour.price}
                          </span>
                        )}
                        {tour.discount && (
                          <div>
                            From{" "}
                            <span className="text-decoration-line-through">
                              {tour.schedules[0].currency || tour.currency}
                              &nbsp;{tour.price}
                            </span>
                            <span className="ms-2 discount-price">
                              {tour.schedules[0].currency || tour.currency}
                              &nbsp;
                              {Math.round(
                                tour.price * (1 - tour.discount / 100)
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                        <div className="float-end text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                          Duration: {tour.duration} Days
                        </div>
                      </div>
                    </Row>

                    <Row className="mb-5">
                      <div className="flex justify-between gap-3 mt-auto col-12 ">
                      <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4">
                        <span className="black-star">
                          <img className="starImg" src={starImg} alt="star" />
                        </span>
                        <span className="ms-2">
                          {tour.rating ? tour.rating : "4.0"}
                        </span>

                        {/* <span className="ms-2">{tour.rating}</span> */}
                        <span className="dot"></span>
                        <span className="text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                          Ratings
                        </span>
                      </div>

                        <div
                          role="button"
                          className="float-end col-lg-5 col-6 font-medium location-text text-wrap"
                          onClick={() => handleTourCardClick(tour)}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "100%",
                          }}
                        >
                          <span className="font-medium text-nowrap">
                            <GeoAlt className="text-muted me-1" />
                            {/* {truncateAddress(tour.formattedAddress)} */}
                            {truncateAddress(tour.location?.address)}
                          </span>
                        </div>
                      </div>
                    </Row>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
          {/* <ToastContainer /> */}
        </Row>

        {hasMoreTours && (
          <div className="text-end mb-2">
            <Button className="view-more-btn" onClick={handleViewMore}>
              Load More Activities
            </Button>
          </div>
        )}
      </Container>
    </>
  );
};

export default MyWhishlist;
