import React, { useState, useRef } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../../assets/css/login.css";
import NavbarHome from "../pages/common/Navbar";
import Footer from "../footer/footer";
import SocialLogin from "./SocialLogin";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { useAuth } from "../../context/Authcontext";

const Login = (onLoginSuccess, handleLoginClose) => {
  const BaseAPI = process.env.REACT_APP_AUTH_SERVER_ENDPOINT;
  const { user, login } = useAuth();

  const [isLogin, setIsLogin] = useState(true);
  const [isOTPVerification, setIsOTPVerification] = useState(false);
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [userId, setUserId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const inputRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    mobileNumber: "",
    roleName: "USER",
    countryCode: "",
    agreedTerms: false,
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    email: "",
    mobileNumber: "",
    confirmPassword: "",
    confirmPasswordRequired: "",
    agreedTerms: "",
    password: "",
  });

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [newPasswordData, setNewPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  const toggleForm = (e) => {
    e.preventDefault();
    setIsLogin(!isLogin);
    resetForm(); 
  };

  const resetForm = () => {
    if (isLogin) {
      setCredentials({ username: "", password: "" });
    } else {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        mobileNumber: "",
        countryCode: "",
      });
    }
    setFormErrors({});
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (formErrors[name]) {
      setFormErrors((prev) => ({ ...prev, [name]: "" }));
    }

    if (isLogin) {
      setCredentials((prev) => ({ ...prev, [name]: value }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleOTPChange = (value, index) => {
    if (/^[0-9]$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Move to the next input field
      if (index < 5 && value !== "") {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === "") {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "") {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const validateName = (firstName) => {
    if (!firstName?.trim()) {
      setFormErrors((prev) => ({
        ...prev,
        firstName: "First name is required.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, firstName: "" }));
    return true;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setFormErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, email: "" }));
    return true;
  };

  const validateMobileNumber = (mobileNumber) => {
    const phoneRegex = /^\d+$/;
    if (!phoneRegex.test(mobileNumber)) {
      setFormErrors((prev) => ({
        ...prev,
        mobileNumber: "Please enter a valid mobile number.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, mobileNumber: "" }));
    return true;
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChar
    );
  };

  const validateConfirmPassword = (confirmPassword) => {
    if (!confirmPassword) {
      setFormErrors((prev) => ({
        ...prev,
        confirmPasswordRequired: "Confirm password is required.",
        confirmPassword: "",
      }));
      return false;
    } else {
      setFormErrors((prev) => ({ ...prev, confirmPasswordRequired: "" }));
    }
    if (confirmPassword) {
      if (formData.password !== confirmPassword) {
        setFormErrors((prev) => ({
          ...prev,
          confirmPassword: "Passwords do not match.",
        }));
        return false;
      } else {
        setFormErrors((prev) => ({ ...prev, confirmPassword: "" }));
      }
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: name === "agreedTerms" ? checked : value,
    }));

    if (name === "email") {
      validateEmail(value);
    } else if (name === "mobileNumber") {
      const extractedMobileNumber = value.slice(-10);
      setFormData((prev) => ({
        ...prev,
        mobileNumber: extractedMobileNumber,
      }));
      console.log("vvvv", extractedMobileNumber);
      validateMobileNumber(extractedMobileNumber);
    } else if (name === "firstName") {
      validateName(value);
    } else if (name === "password") {
      validatePassword(value);
    } else if (name === "confirmPassword") {
      validateConfirmPassword(value);
    }
  };

  //google login
  const handleSocialLoginSuccess = async (token, provider) => {
    try {
      console.log("provider==>", provider);
      const endpoint =
        provider === "google" ? "/auth/google" : "/auth/facebook";
      const eurekatripSocialLoginEndPoint = BaseAPI + endpoint;

      console.log("Making POST request to:", eurekatripSocialLoginEndPoint);

      const response = await axios.post(eurekatripSocialLoginEndPoint, {
        token,
      });

      if (response && response.data) {
        console.log("Login successful, received data:", response.data);

        localStorage.setItem("user", JSON.stringify(response.data));
        login(response.data.data);

        console.log("User successfully logged in, navigating to homepage...");
        navigate("/");
      }
    } catch (error) {
      console.error("There was an error!", error);
      toast.error("Login failed, please try again.");
    }
  };

  // Handle Login
  const handleLogin = async (e) => {
    e.preventDefault();

    const errors = {};
    if (!credentials.username) {
      errors.username = "Email is required";
    }
    if (!credentials.password) {
      errors.password = "Password is required";
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; 
    }


    if (isLoading) return;

    setIsLoading(true);

    try {
      const userEmail = credentials.username;
      const userEmailResponse = await axios.get(
        `${BaseAPI}/rest/auth/userEmail/${userEmail}`
      );
      if (userEmailResponse.status !== 200) {
        throw new Error("Invalid Credential, please try again.");
      }

      const loginResponse = await axios.post(
        `${BaseAPI}/rest/auth/login`,
        credentials
      );
      if (loginResponse.status === 200) {
        localStorage.setItem("token", loginResponse.data.token);
        localStorage.setItem("user", JSON.stringify(loginResponse.data.data));
        toast.success("Login successful");

        navigate("/");

        window.location.reload();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Incorrect username or password. Please try again.");
      } else {
        toast.error(
          error.response
            ? error.response.data.message
            : "Login failed. Please check your credentials."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Registration
  const handleRegister = async (e) => {
    e.preventDefault();
    if (isLoading) return;

    setIsLoading(true);

 
    const isNameValid = validateName(formData.firstName);
    const isEmailValid = validateEmail(formData.email);
    const isMobileNumberValid = validateMobileNumber(formData.mobileNumber);

    if (!isNameValid || !isEmailValid || !isMobileNumberValid) {
      console.error("Validation errors:", {
        nameValid: isNameValid,
        emailValid: isEmailValid,
        mobileValid: isMobileNumberValid,
      });
  
      toast.error("Please fill out all fields correctly.");
      setIsLoading(false); // Reset loading state
      return;
    }


    const mobileWithoutCountryCode = formData.mobileNumber.substring(
      formData.countryCode.length
    );

    const registerData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      countryCode: formData.countryCode,
      mobileNumber: parseInt(mobileWithoutCountryCode, 10),
      email: formData.email,
      roleName: "USER",
    };

    try {
      const response = await axios.post(
        `${BaseAPI}/rest/auth/signup`,
        registerData
      );

      if (response.data.statusCode === 200) {
        toast.success("OTP Send successful");
        setUserId(response.data.userId);
        setIsOTPVerification(true);
      } else if (response.data.statusCode === 409) {
        toast.error("Email is already registered.");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Registration failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // OTP Verification handler
  const handleOTPVerification = async (e) => {
    e.preventDefault();

    if (isLoading) return;

    setIsLoading(true);
    const enteredOtp = otp.join("");

    const otpData = {
      email: formData.email,
      inputOTP: enteredOtp,
    };

    try {
      const response = await fetch(`${BaseAPI}/rest/auth/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(otpData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.message || "Something went wrong with the OTP verification."
        );
      }

      if (data.statusCode === 200) {
        setIsOTPVerification(false);
        setIsPasswordSet(true);
        toast.success("OTP verified Successfully", { autoClose: 3000 });
      } else if (data.statusCode === 401 && data.message === "Invalid OTP") {
        setErrorMessage(
          "The OTP you entered is invalid. Please check and try again."
        );
      } else {
        setErrorMessage("OTP verification failed. Please try again later.");
      }

      console.log("OTP verification successful:", data);
    } catch (error) {
      console.log("Error during OTP verification:", error.message);
      setErrorMessage("Failed to verify OTP. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  // Set New Password handler
  const handleSetPassword = async (e) => {
    e.preventDefault();

    if (isLoading) return;

    setIsLoading(true);

    setPasswordError("");
    setConfirmPasswordError("");
    let isValid = true;

    if (!newPasswordData.password || !newPasswordData.confirmPassword) {
      setPasswordError("password is required.");
      isValid = false;
    } else if (!validatePassword(newPasswordData.password)) {
      setPasswordError(
        "Password must be at least 8 characters long, include uppercase and lowercase letters, a number, and a special character."
      );
      isValid = false;
    }

   
    if (!newPasswordData.password || !newPasswordData.confirmPassword) {
      setConfirmPasswordError("confirm Password is required.");
      isValid = false;
    }
   else if (newPasswordData.password !== newPasswordData.confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      isValid = false;
    }

    if (!isValid) {
      setIsLoading(false); // Ensure the button is re-enabled
      return;
    }
    const passwordData = {
      email: formData.email,
      password: newPasswordData.password,
      confirmPassword: newPasswordData.confirmPassword,
    };

    try {
      const response = await axios.post(
        `${BaseAPI}/rest/auth/set-password`,
        passwordData
      );

      if (response.status === 200) {
        toast.success("Password set successfully. Please login.", {
          autoClose: 3000,
        });
        setIsLogin(true);
        setIsPasswordSet(false);
      } else {
        toast.error("Failed to set password. Please try again.");
      }
    } catch (error) {
      toast.error(
        error.response
          ? error.response.data.message
          : "Failed to set password. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setNewPasswordData((prev) => ({ ...prev, [name]: value }));

    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
      setConfirmPasswordError("");
    }
  };

  // Forgot Password: Send Reset Link
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BaseAPI}/rest/auth/forgot-password`,
        {
          email: formData.email,
        }
      );
      if (response.data.statusCode === 200) {
        toast.success("Password reset OTP sent to your email.");
        setIsForgotPassword(false);
        setIsOTPVerification(true);
      } else {
        toast.error(response.data.message || "Failed to send reset OTP.");
      }
    } catch (error) {
      toast.error("Error sending reset OTP. Please try again.");
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  return (
    <>
      <div className="mb-5">
        <NavbarHome />
      </div>
      <div className="login-page">
        <Container className="mb-5 mt-5 d-flex justify-content-center align-items-center font-common">
          <Row className="w-100 justify-content-center">
            <Col xs={12} md={6} lg={4}>
              {isForgotPassword ? (
                <>
                  <div className="text-center mb-4 mt-5">
                    <h3 className="login-header fw-bold">Forgot Password</h3>
                    <p className="signup-text">
                      Enter your email to receive a password reset OTP.
                    </p>
                  </div>
                  <Form onSubmit={handleForgotPassword}>
                    <Form.Group controlId="formEmail" className="mb-3">
                      <Form.Label className="fw-bold">
                        Email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="username"
                        placeholder="Enter email"
                        required
                        className="form-control"
                        onChange={(e) =>
                          setFormData({ ...formData, email: e.target.value })
                        }
                        value={formData.email}
                      />
                    </Form.Group>

                    <Button type="submit" className="w-100 btn-custom">
                      Send Reset OTP &rarr;
                    </Button>
                  </Form>
                  <div className="text-center mt-3 fw-bold">
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        setIsForgotPassword(false);
                        setIsLogin(true);
                      }}
                    >
                      Back to login
                    </a>
                  </div>
                </>
              ) : isOTPVerification ? (
                <>
                  <div className="text-center mb-4 mt-5">
                    <h3 className="login-header fw-bold">OTP Verification</h3>
                    <p className="signup-text">
                      OTP sent to your registered{" "}
                      <span className="text-primary">{formData.email}</span>,
                      please check.
                    </p>
                  </div>
                  <Form onSubmit={handleOTPVerification}>
                    <div className="otp-inputs d-flex justify-content-center mb-3">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          maxLength="1"
                          value={digit}
                          className="otp-input-box mx-1 text-center"
                          onChange={(e) =>
                            handleOTPChange(e.target.value, index)
                          }
                          onKeyDown={(e) => handleBackspace(e, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                          style={{
                            width: "40px",
                            height: "40px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            fontSize: "20px",
                          }}
                        />
                      ))}
                    </div>
                    {errorMessage && (
                      <div className="text-danger mb-3">{errorMessage}</div>
                    )}
                    <Button
                      type="submit"
                      className="w-100 btn-custom"
                      onClick={handleOTPVerification}
                      disabled={isLoading}
                    >
                      {isLoading ? "Verifying OTP..." : "Verify OTP →"}
                    </Button>
                  </Form>
                </>
              ) : isPasswordSet ? (
                // Set Password Form
                <>
                  <div className="text-center mb-4 mt-5">
                    <h3 className="login-header fw-bold">Set New Password</h3>
                    <p className="signup-text">
                      Please set a new password for your account.
                    </p>
                  </div>
                  <Form onSubmit={handleSetPassword}>
                    <Form.Group controlId="formNewPassword" className="mb-3">
                      <Form.Label className="fw-bold">
                        New Password <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="input-group">
                        <Form.Control
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          placeholder="Enter new password"
                          className="form-control"
                          onChange={handlePasswordInputChange}
                          value={newPasswordData.password}
                          style={{ paddingRight: "40px" }}
                        />
                        <span
                          className="input-group-text eye-buttons"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordShown ? <BsEye /> : <BsEyeSlash />}
                        </span>
                      </div>
                      {passwordError && (
                        <div className="text-danger mt-2">{passwordError}</div>
                      )}
                    </Form.Group>

                    <Form.Group
                      controlId="formConfirmPassword"
                      className="mb-3"
                    >
                      <Form.Label className="fw-bold">
                        Confirm Password <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="input-group">
                        <Form.Control
                          type={confirmPasswordShown ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="Confirm new password"
                          className="form-control"
                          onChange={handlePasswordInputChange}
                          value={newPasswordData.confirmPassword}
                          style={{ paddingRight: "40px" }}
                        />
                        <span
                          className="input-group-text eye-buttons"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {confirmPasswordShown ? <BsEye /> : <BsEyeSlash />}
                        </span>
                      </div>
                      {confirmPasswordError && (
                        <div className="text-danger mt-2">
                          {confirmPasswordError}
                        </div>
                      )}
                    </Form.Group>

                    <Button
                      type="submit"
                      className="w-100 btn-custom"
                      onClick={handleSetPassword}
                      disabled={isLoading}
                    >
                      {isLoading ? "Setting Password..." : "Set Password →"}
                    </Button>
                  </Form>
                </>
              ) : (
                <>
                  <div className="text-center mb-4 mt-2">
                    {isLogin ? (
                      <>
                        <h3 className="login-header fw-bold">
                          Log in to account
                        </h3>
                        <p className="signup-text">
                          Don't have an account?{" "}
                          <a href="#" onClick={toggleForm}>
                            Sign up
                          </a>{" "}
                          for an account now.
                        </p>
                      </>
                    ) : (
                      <>
                        <h3 className="login-header fw-bold">
                          Create a new account
                        </h3>
                        <p className="signup-text">
                          Already have an account?{" "}
                          <a href="#" onClick={toggleForm}>
                            Log in
                          </a>{" "}
                          to your account now.
                        </p>
                      </>
                    )}
                  </div>

                  {/* Social Login Buttons */}
                  <div className="w-100 mb-3">
                    <SocialLogin
                      onSocialLoginSuccess={handleSocialLoginSuccess}
                    />
                  </div>

                  {/* Divider */}
                  <div className="d-flex align-items-center mb-2">
                    <hr className="flex-grow-1" />
                    <span className="mx-2">or</span>
                    <hr className="flex-grow-1" />
                  </div>

                  {/* Login or Register Form */}
                  {isLogin ? (
                    <Form onSubmit={handleLogin}>
                      <Form.Group controlId="formEmail" className="mb-3">
                        <Form.Label className="fw-bold">
                          Email <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="username"
                          placeholder="Enter email"
                          required
                          className="form-control"
                          onChange={handleInputChange}
                          value={credentials.username}
                        />
                          {formErrors.username && (
          <div style={{ color: "red", fontSize: "small" }}>
            {formErrors.username}
          </div>
        )}
                      </Form.Group>

                      <Form.Group controlId="formPassword" className="mb-3">
                        <Form.Label className="fw-bold">
                          Password <span className="text-danger">*</span>
                        </Form.Label>
                        <div className="input-group">
                          <Form.Control
                            type={passwordShown ? "text" : "password"}
                            name="password"
                            placeholder="Enter password"
                            required
                            className="form-control"
                            onChange={handleInputChange}
                            value={credentials.password}
                            style={{ paddingRight: "40px" }}
                          />
                          <span
                            className="input-group-text eye-buttons"
                            onClick={togglePasswordVisibility}
                          >
                            {passwordShown ? <BsEye /> : <BsEyeSlash />}
                          </span>
                        </div>
                        {formErrors.password && (
          <div style={{ color: "red", fontSize: "small" }}>
            {formErrors.password}
          </div>
        )}
                      </Form.Group>

                      <div className="d-flex justify-content-between mb-3">
                        <a
                          href=""
                          className="text-primary fw-bold"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsForgotPassword(true);
                          }}
                        >
                          Forgot your password?
                        </a>
                      </div>

                      <Button
                        type="submit"
                        className="w-100 btn-custom mb-5"
                        onClick={handleLogin}
                        disabled={isLoading}
                      >
                        {isLoading ? "Logging in..." : "Log in →"}
                      </Button>
                    </Form>
                  ) : (
                    <Form onSubmit={handleRegister}>
                      <Form.Group controlId="formFirstName" className="mb-2">
                        <Form.Label className="fw-bold">
                          First Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder="Enter first name"
                          required
                          className="form-control"
                          onChange={handleChange}
                          value={formData.firstName}
                        />
                        {formErrors.firstName && (
                          <div style={{ color: "red", fontSize: "small" }}>
                            {formErrors.firstName}
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formLastName" className="mb-2">
                        <Form.Label className="fw-bold">
                          Last Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          placeholder="Enter last name"
                          required
                          className="form-control"
                          onChange={handleChange}
                          value={formData.lastName}
                        />
                      </Form.Group>

                      <Form.Group controlId="formEmail" className="mb-2">
                        <Form.Label className="fw-bold">
                          Email <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          required
                          className="form-control"
                          onChange={handleChange}
                          value={formData.email}
                        />
                        {formErrors.email && (
                          <div style={{ color: "red", fontSize: "small" }}>
                            {formErrors.email}
                          </div>
                        )}
                      </Form.Group>

                      <Form.Group controlId="formMobile" className="mb-3">
                        <Form.Label className="fw-bold">
                          Mobile Number <span className="text-danger">*</span>
                        </Form.Label>
                        <PhoneInput
                          country={"in"}
                          value={formData.mobileNumber}
                          onChange={(phone, country) => {
                            const mobileWithoutCountryCode = phone.replace(
                              `+${country.dialCode}`,
                              ""
                            );
                            setFormData((prev) => ({
                              ...prev,
                              mobileNumber: mobileWithoutCountryCode,
                              countryCode: country.dialCode,
                            }));
                          }}
                          inputProps={{
                            name: "mobileNumber",
                            required: true,
                          }}
                          inputStyle={{ width: "100%", height: "42px" }}
                          inputClass="form-control mobile-input"
                        />
                        {formErrors.mobileNumber && (
                          <div style={{ color: "red", fontSize: "small" }}>
                            {formErrors.mobileNumber}
                          </div>
                        )}
                      </Form.Group>

                      <Button
                        type="submit"
                        className="w-100 btn-custom"
                        onClick={handleRegister}
                        disabled={isLoading}
                      >
                        {isLoading ? "Signing up..." : "Sign up →"}
                      </Button>
                    </Form>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Login;
