import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavbarHome from "../common/Navbar";
import "../../../assets/css/bookedTours.css";
import { ToastContainer, toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  Card,
  Carousel,
  Button,
  Modal,
} from "react-bootstrap";
import {
  ArrowLeft,
  ArrowRight,
  Bookmark,
  BookmarkFill,
  GeoAlt,
} from "react-bootstrap-icons";
import axios from "axios";
import starImg from "../../../assets/image/Star 13.png";
import defaultTourImg from "../../../assets/image/9b.jpg"; 

const BookedTours = () => {
  const BaseAPIAuth = process.env.REACT_APP_AUTH_SERVER_ENDPOINT;
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));

  const [bookingData, setBookingData] = useState([]);
  const [earnedMiles, setEarnedMiles] = useState([]);
  const [selectedTab, setSelectedTab] = useState("upcoming");
  const [showModal, setShowModal] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const [wishlist, setWishlist] = useState(new Set());

  // For loading past tours incrementally
  const [displayedPastTours, setDisplayedPastTours] = useState([]);
  const [pastBookingData, setPastBookingData] = useState([]);

  useEffect(() => {
    fetchBookingData();
    fetchEarnedMiles();
  }, []);

  const fetchBookingData = async () => {
    try {
      const response = await axios.get(`${BaseAPI}/tour/booking/user/${userData.userId}`);
      const data = response.data.data || [];
      setBookingData(data);

      // Set past tours based on the fetched data
      const pastTours = data.filter((booking) => new Date(booking.schedule.startDate) <= new Date());
      setPastBookingData(pastTours);
      setDisplayedPastTours(pastTours.slice(0, 4)); 
    } catch (error) {
      console.error("Error fetching booking data:", error);
    }
  };

  const fetchEarnedMiles = async () => {
    try {
      const response = await axios.get(`${BaseAPIAuth}/rest/auth/earned-miles/user/${userData.userId}`);
      setEarnedMiles(response.data.data.milesEarned);
    } catch (error) {
      console.error("Error fetching earned miles:", error);
    }
  };

  const handleTourCardClick = (tour) => {
    setSelectedTour(tour);
    setShowModal(true);
  };

  const addToWishlist = async (tourId) => {
    if (!userData?.userId) {
       toast.error("Please login first");
      return;
    }
    try {
      await axios.post(`${BaseAPI}/tour/save/favTour`, { tourId, userId: userData?.userId });
      setWishlist((prevWishlist) => new Set(prevWishlist).add(tourId));
      toast.success("Added to your Wishlist");
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const removeFromWishlist = async (tourId) => {
    if (!userData?.userId) {
       toast.error("Please login first");
      return;
    }
    try {
      await axios.delete(`${BaseAPI}/tour/delete/fav-tour`, { data: { tourId, userId: userData?.userId } });
      setWishlist((prevWishlist) => {
        const newWishlist = new Set(prevWishlist);
        newWishlist.delete(tourId);
        return newWishlist;
      });
      toast.success("Successfully removed from wishlist!");
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleViewMorePastTours = () => {
    const nextTours = pastBookingData.slice(
      displayedPastTours.length,
      displayedPastTours.length + 4
    );
    setDisplayedPastTours([...displayedPastTours, ...nextTours]);
  };

  const hasMorePastTours = displayedPastTours.length < pastBookingData.length;

  const directionButtons = (direction) => (
    <span>
      {direction === "Next" ? <ArrowRight className="rightArrow" /> : <ArrowLeft className="rightArrow" />}
    </span>
  );

  // Modal close handler
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedTour(null);
  };

  return (
    <div>
      <NavbarHome />
      <div className="book-table mt-5" style={{ padding: "20px", minHeight: "100vh" }}></div>
      <Container fluid className="custom-container h-auto mt-4 px-5">
        <div className="expedition-title" style={{ marginTop: "70px" }}>
          Earned Miles: {earnedMiles}
        </div>

        <div className="tabs mt-4">
          <button
            className={`tab-btn ${selectedTab === "upcoming" ? "active" : ""}`}
            onClick={() => setSelectedTab("upcoming")}
          >
            Upcoming Tours
          </button>
          <button
            className={`tab-btn ${selectedTab === "past" ? "active" : ""}`}
            onClick={() => setSelectedTab("past")}
          >
            Past Tours
          </button>
        </div>

        <Row>
          {selectedTab === "upcoming" && (
            bookingData.filter((tour) => new Date(tour.schedule.startDate) > new Date()).length > 0 ? (
              bookingData.filter((tour) => new Date(tour.schedule.startDate) > new Date()).map((tour, idx) => (
                <Col xs={12} sm={6} md={4} lg={3} key={idx}>
                  <Card className="h-100">
                    <div>
                      <div className="carousel-hover">
                        <Carousel className="carousel-overlay" interval={null} nextIcon={directionButtons("Next")} prevIcon={directionButtons("Previous")}>
                          {tour.schedule.tour.tourAssets.map((asset, assetIdx) => (
                            <Carousel.Item key={assetIdx} className="card-img-container">
                              <img onClick={() => handleTourCardClick(tour)} className="" src={asset.assetUrl || defaultTourImg} alt={`Slide ${assetIdx}`} />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                        <img onClick={() => handleTourCardClick(tour)} variant="top" src={tour.schedule.tour.tourAssets[0]?.assetUrl || defaultTourImg} className="card-img-container" />
                        <div className="overlay-text">{tour.schedule.tour.tourTheme[0].theme.themeName}</div>
                        <div className="bookmark-icon">
                          {wishlist.has(tour.tourId) ? (
                            <BookmarkFill onClick={() => removeFromWishlist(tour.tourId)} style={{ color: "white" }} />
                          ) : (
                            <Bookmark onClick={() => addToWishlist(tour.schedule.tour.tourId)} />
                          )}
                        </div>
                      </div>

                      <div className="d-flex flex-column mt-2">
                        <span className="font-medium-title feature-title">{tour.schedule.tour.title}</span>
                        <Row>
                          <div>
                            <div className="d-inline z-30 font-feature-tour">
                              From <span className="me-2">{tour.schedule.currency}</span> {tour.schedule.price}
                            </div>
                            <div className="float-end text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                              Duration {tour.schedule.duration}
                               {/* {tour.schedule.durationTime} */}
                            </div>
                          </div>
                        </Row>

                        <Row className="mb-5">
                          <div className="flex justify-between gap-3 mt-auto col-12">
                            <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4">
                              <span className="black-star">
                                <img className="starImg" src={starImg} alt="star" />
                              </span>
                              <span className="ms-2">5.0</span>
                              <span className="dot"></span>
                              <span className="text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">3 Ratings</span>
                            </div>

                            <div role="button" className="float-end col-lg-5 col-6 font-medium location-text text-wrap" onClick={() => handleTourCardClick(tour)}>
                              <span className="font-medium">
                                <GeoAlt className="text-muted me-1" />
                                Pune, India
                                {/* {tour.location ? tour.location.address : "Location not available"} */}

                              </span>
                            </div>

                            <div className="mt-2">
                              <Button variant="" className="bg-white build-trip-button text-wrap" onClick={() => handleTourCardClick(tour)}>
                                <span className="trip-element text-wrap">Booking Details</span>
                              </Button>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))
            ) : (
              <Col xs={12} sm={6} md={4} lg={3} className="no-tours-message">
                <Card className="h-100">
                  <div className="no-tours-bg card-img-container">
                    <Card.Title className="upcoming-title">No Upcoming Tours</Card.Title>
                  </div>
                  <Card.Body>
                    <Card.Text>
                      You don't have any upcoming tours at the moment. Check out our available tours and start booking your next adventure!
                    </Card.Text>
                    <Button onClick={() => navigate("/")}>Explore Tours</Button>
                  </Card.Body>
                </Card>
              </Col>
            )
          )}

          {selectedTab === "past" && (
            displayedPastTours.length > 0 ? (
              displayedPastTours.map((tour, idx) => (
                <Col xs={12} sm={6} md={4} lg={3} key={idx}>
                  <Card className="h-100">
                    <div>
                      <div className="carousel-hover">
                        <Carousel className="carousel-overlay" interval={null} nextIcon={directionButtons("Next")} prevIcon={directionButtons("Previous")}>
                          {tour.schedule.tour.tourAssets.map((asset, assetIdx) => (
                            <Carousel.Item key={assetIdx} className="card-img-container">
                              <img onClick={() => handleTourCardClick(tour)} className="" src={asset.assetUrl || defaultTourImg} alt={`Slide ${assetIdx}`} />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                        <img onClick={() => handleTourCardClick(tour)} variant="top" src={tour.schedule.tour.tourAssets[0]?.assetUrl || defaultTourImg} className="card-img-container" />
                        <div className="overlay-text">{tour.schedule.tour.tourTheme[0].theme.themeName}</div>
                        <div className="bookmark-icon">
                          {wishlist.has(tour.tourId) ? (
                            <BookmarkFill onClick={() => removeFromWishlist(tour.tourId)} style={{ color: "white" }} />
                          ) : (
                            <Bookmark onClick={() => addToWishlist(tour.schedule.tour.tourId)} />
                          )}
                        </div>
                      </div>

                      <div className="d-flex flex-column mt-2">
                        <span className="font-medium-title feature-title">{tour.schedule.tour.title}</span>
                        <Row>
                          <div>
                            <div className="d-inline z-30 font-feature-tour">
                              From <span className="me-2">{tour.schedule.currency}</span> {tour.schedule.price}
                            </div>
                            <div className="float-end text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">
                              Duration {tour.schedule.duration}
                              {/* {tour.schedule.durationTime} */}
                            </div>
                          </div>
                        </Row>

                        <Row className="mb-5">
                          <div className="flex justify-between gap-3 mt-auto col-12">
                            <div className="d-inline font-feature-tour transformation-tour-description col-lg-3 col-4">
                              <span className="black-star">
                                <img className="starImg" src={starImg} alt="star" />
                              </span>
                              <span className="ms-2">{tour.schedule.tour.rating ? tour.schedule.tour.rating : "4.0"}</span>
                              <span className="dot"></span>
                              <span className="text-sm 2xl:text-standard text-gray-700 dark:text-gray-400 font-feature-tour">Ratings</span>
                            </div>

                            <div role="button" className="float-end col-lg-5 col-6 font-medium location-text text-wrap" onClick={() => handleTourCardClick(tour)}>
                              <span className="font-medium">
                                <GeoAlt className="text-muted me-1" />
                                {/* {tour.schedule.address} */}
                                Pune, India
                              </span>
                            </div>
                            <div className="mt-2">
                              <Button variant="" className="bg-white build-trip-button text-wrap" onClick={() => handleTourCardClick(tour)}>
                                <span className="trip-element text-wrap">Booking Details</span>
                              </Button>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </Col>
              ))
            ) : (
              <Col xs={12}>
                <div className="no-tours-message">
                  <h4>No Past Tours Available</h4>
                  <p>You haven't completed any tours yet.</p>
                </div>
              </Col>
            )
          )}
        </Row>

        {selectedTab === "past" && hasMorePastTours && (
          <div className="text-end mb-2 no-tours-message">
            <Button className="view-more-btn" onClick={handleViewMorePastTours}>
              Load More Activities
            </Button>
          </div>
        )}
      </Container>

      {/* Booking Details Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered className="custom-modal">
        <Modal.Header closeButton className="custom-modal-header">
          <Modal.Title className="custom-modal-title">Booking Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          {selectedTour && (
            <div className="custom-modal-content">
              <p><strong>Title:</strong> {selectedTour.schedule.tour.title}</p>
              <p><strong>Booking Date:</strong> {`${new Date(selectedTour.bookingDate).getFullYear()}-${(new Date(selectedTour.bookingDate).getMonth() + 1).toString().padStart(2, '0')}-${new Date(selectedTour.bookingDate).getDate().toString().padStart(2, '0')}`}</p>
              <p><strong>Number of Participants:</strong> {selectedTour.numberOfParticipants}</p>
              <p><strong>Total Amount:</strong> {selectedTour.schedule.currency} {selectedTour.amount}</p>
              <p><strong>Tour Duration:</strong> {selectedTour.schedule.duration} Days
               {/* {selectedTour.schedule.durationTime} */}
               </p>
              <p><strong>Location:</strong> {selectedTour.schedule.tour.address}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
};

export default BookedTours;
