import React, { useEffect, useRef, useState } from "react";
import Navbar from "../common/Navbar";
import "react-datepicker/dist/react-datepicker.css";
import Discover from "../common/Discover";
import Footer from "../../footer/footer";
import Recomandation from "../common/Recomandation";
import TourBooking from "../booked-tour/TourBooking";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import MapView from "./MapVeiw";
import "leaflet/dist/leaflet.css";
import "../../../assets/css/schedule-tours.css";
import defaultImage from "../../../assets/image/9b.jpg";
import linkImg from "../../../assets/image/link.png";
import messegeImg from "../../../assets/image/messenger.png";
import emailImg from "../../../assets/image/email.png";
import share from "../../../assets/image/share.png";
import he from "he";
import {
  GeoAlt,
  Bookmark,
  BookmarkFill,
  Whatsapp,
} from "react-bootstrap-icons";
import ScheduleItinerary from "./ScheduleItinerary";
import { IoChevronForward } from "react-icons/io5";

const ScheduleTour = ({ bookedTourId, isTourBooked }) => {
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;
  const userData = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(true);

  const location = useLocation();
  const {
    tourId,
    pageName,
    selectedCategory,
    selectedSubCategory,
    selectedThemeName,
    selectedThemeId,
  } = location.state || {};

  const linkToCopy = `https://eurekatrip.com/#/tour/${tourId}`;
  const [veiwImagesPopup, setVeiwImagesPopup] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [tourData, setTourData] = useState({});
  const [scheduleId, setScheduleId] = useState();
  const [showBookPopup, setShowBookPopup] = useState(false);
  const [selectedTourId, setSelectedTourId] = useState();
  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const [mapUrl, setMapUrl] = useState("");
  const [selectedScheduleId, setSelectedScheduleId] = useState();
  const [taggedsubCategoryCategory, setTaggedsubCategoryCategory] = useState(
    []
  );
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const [images, setImages] = useState([]);
  const [showFullHighlights, setShowFullHighlights] = useState(false);
  const [showFullAdditionalInfo, setShowFullAdditionalInfo] = useState(false);
  const [showFullIncluded, setShowFullIncluded] = useState(false);
  const [showFullNotIncluded, setShowFullNotIncluded] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [formData, setFormData] = useState({ numberOfParticipants: "" });
  const [isMobileVisible, setIsMobileVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setshareModal] = useState(false);
  const mapRef = useRef(null);
  const highlightLimit = 200;
  const additionalInfoLimit = 200;
  const includedLimit = 200;
  const notIncludedLimit = 200;
  const [isSticky, setIsSticky] = useState(false);
  const scheduleRef = useRef(null);
  const bottomObserverRef = useRef(null);
  const topObserverRef = useRef(null);
  const additionalInfoRef = useRef(null);
  const stickyHeaderRef = useRef(null);

  const highlightContent = showFullHighlights
    ? tourData?.highlights
    : tourData?.highlights?.substring(0, highlightLimit) + "";

  const additionalInfoContent = showFullAdditionalInfo
    ? tourData?.additionalInformation
    : tourData?.additionalInformation?.substring(0, additionalInfoLimit) +
      "";

  const includedContent = showFullIncluded
    ? tourData?.included
    : tourData?.included?.substring(0, includedLimit) + "";

  const notIncludedContent = showFullNotIncluded
    ? tourData?.notIncluded
    : tourData?.notIncluded?.substring(0, notIncludedLimit) + "";

  const shouldShowLearnMore = (text) => {
    return text?.length > highlightLimit;
  };

  const renderDescription = (description, showFullDescription) => {
    if (showFullDescription) {
      return description;
    }
    return description?.substring(0, highlightLimit) + "";
  };

  // State for toggling "Learn More"
  const [showFullDescription, setShowFullDescription] = React.useState(false);

  const handleMediaSelect = (media) => {
    setSelectedMedia(media);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    const handleResize = () => {
      console.log("window.innerWidth", window.innerWidth);
      console.log("window.innerWidth <= 768", window.innerWidth <= 768);
      window.innerWidth <= 768
        ? setIsMobileVisible(true)
        : setIsMobileVisible(false);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const hash = window.location.hash;
    const paramTourId = hash.split("/")[2];

    if (paramTourId) {
      setSelectedTourId(paramTourId);
    }
    if (bookedTourId) {
      setSelectedTourId(bookedTourId);
    } else if (tourId) {
      setSelectedTourId(tourId);
    }
  }, [bookedTourId, tourId]);

  useEffect(() => {
    if (selectedTourId) {
      fetchTourData();
    }
  }, [selectedTourId]);

  const handleClose = () => setShowBookPopup(false);

  useEffect(() => {
    if (tourData.schedules && tourData.schedules.length > 0) {
      setStartDate(new Date(tourData.schedules[0].scheduleDate));
    }
  }, [tourData.schedules]);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  useEffect(() => {
    let previousScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const topOffset = topObserverRef.current?.offsetTop || 0;
      const bottomOffset =
        (bottomObserverRef.current?.offsetTop || 0) -
        (stickyHeaderRef.current?.offsetHeight || 0);
      const additionalInfoOffset = additionalInfoRef.current?.offsetTop || 0;

      if (
        currentScrollY > topOffset &&
        currentScrollY < bottomOffset &&
        currentScrollY < additionalInfoOffset
      ) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      previousScrollY = currentScrollY;
    };

    const debouncedHandleScroll = debounce(handleScroll, 50);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, []);

  const fetchTourData = async () => {
    try {
      const endpoint = userData?.userId
        ? `/tour/${selectedTourId}/user/${userData?.userId}`
        : `/tour/${selectedTourId}`;
      const response = await fetch(`${BaseAPI}${endpoint}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      setWishlist(data.data.isFavourite);
      const scheduleId =
        data.data.schedules?.length > 0
          ? data.data.schedules[0].schedule_id
          : null;
      setScheduleId(scheduleId);
      setTourData(data.data);
      setSchedules(data.data.schedules);
      console.log("data.data.schedules", data.data.schedules);

      const filteredSchedules = data.data.schedules.filter(
        (schedule) => schedule.status !== 0
      );

      setTourData(data.data);
      setSchedules(filteredSchedules);
      console.log("Filtered schedules", filteredSchedules);

      const images = data.data.tourAssets.filter(
        (asset) => asset.assetType === "OTHERS" || asset.assetType === "IMAGE"
      );
      setImages(images);

      const tourThemes = data.data.tourTheme || [];

      if (selectedCategory && selectedSubCategory) {
        const filterTaggedCategoryAndSubcategory = tourThemes.filter(
          (theme) =>
            theme.category?.categoryId !== selectedCategory ||
            theme.subCategory?.subCategoryId !== selectedSubCategory
        );
        setTaggedsubCategoryCategory(filterTaggedCategoryAndSubcategory);

        const filteredThemes = tourThemes.filter(
          (theme) =>
            theme.category?.categoryId === selectedCategory &&
            theme.subCategory?.subCategoryId === selectedSubCategory
        );

        if (filteredThemes.length > 0) {
          setCategory(filteredThemes[0].category);
          setSubCategory(filteredThemes[0].subCategory);
        }
      } else {
        const filterTaggedCategoryAndSubcategory = tourThemes.filter(
          (theme) => theme.theme?.themeId !== selectedThemeId
        );

        setTaggedsubCategoryCategory(filterTaggedCategoryAndSubcategory);
      }

      if (images.length > 0) {
        handleMediaSelect(images[0]);
        setSelectedMedia(images[0]);
      }

      const startDate =
        data.data.schedules.length > 0
          ? new Date(data.data.schedules[0]?.scheduleDate)
          : new Date();
      setStartDate(startDate);

      const locationData = data.data.location;
      setMapUrl({
        placeId: locationData.placeId,
        latitude: parseFloat(locationData.latitude),
        longitude: parseFloat(locationData.longitude),
      });
      

    } catch (error) {
      console.error("Error fetching tour data:", error.message);
    }
    
  };


  const handleBookNow = () => {
    console.log("formData", formData);
    const Participants = formData.numberOfParticipants;
    navigate("/razor-pay", {
      state: {
        tourId,
        selectedSchedule: selectedSchedule,
        Participants: Participants,
      },
    });
  };

  const handleViewMore = () => {
    setShowFullHighlights(true);
  };

  const handleViewLess = () => {
    setShowFullHighlights(false);
  };

  const handleViewMoreInfo = () => {
    setShowFullAdditionalInfo(true);
  };

  const handleViewLessInfo = () => {
    setShowFullAdditionalInfo(false);
  };

  const handleViewMoreIncluded = () => {
    setShowFullIncluded(true);
  };

  const handleViewLessIncluded = () => {
    setShowFullIncluded(false);
  };

  const handleViewMoreNotIncluded = () => {
    setShowFullNotIncluded(true);
  };

  const handleViewLessNotIncluded = () => {
    setShowFullNotIncluded(false);
  };

  const handleLearnMore = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handlePopupClose = () => {
    setShowModal(false);
  };

  const handleScheduleDateClick = (schedule) => {
    setShowParticipants(true);
    setSelectedScheduleId(schedule?.scheduleId);
    setSelectedSchedule(schedule);
  };

  const renderHighlights = (highlights) => {
    if (highlights) {
      const decodedHighlights = he?.decode(highlights);
      const lines = decodedHighlights.split("\n");

      return lines.map((line, index) => (
        <div
          key={index}
          className="text-gray-700 dark:text-gray-400 font-feature-tour"
          dangerouslySetInnerHTML={{ __html: line }}
        ></div>
      ));
    }
  };

  const veiwGallery = () => {
    setVeiwImagesPopup(true);
  };
  const closeGallery = () => {
    setVeiwImagesPopup(false);
  };

  const veiwSharePopup = () => {
    setshareModal(true);
  };
  const closeSharePopup = () => {
    setshareModal(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClick = () => {
    if (mapRef.current) {
      mapRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const addToWishlist = async () => {
    if (!userData?.userId) {
       toast.error("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    const response = await fetch(`${BaseAPI}/tour/save/favTour`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`Failed to add to wishlist: ${response.statusText}`);
    }

    const responseData = await response.json();

    if (responseData) {
      setWishlist(true);
      toast.success("Added to your Wishlist");
    }
  };

  const removeFromWishlist = async () => {
    if (!userData?.userId) {
       toast.error("Please login first");
      return;
    }

    const data = {
      tourId: tourId,
      userId: userData?.userId,
    };

    try {
      const response = await fetch(`${BaseAPI}/tour/delete/fav-tour`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(
          `Failed to remove from wishlist: ${response.statusText}`
        );
      }

      const responseData = await response.json();

      if (responseData) {
        setWishlist(false);
        toast.success("Successfully removed from wishlist!");
      }
    } catch (error) {
       toast.error(`Error: ${error.message}`);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
         toast.error("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy the link: ", err);
      });
  };
  const handleWhatsAppShare = () => {
    const message = "Check out this tour: ";
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      message + linkToCopy
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  const handleEmailShare = () => {
    const subject = "Interesting Tour Information";
    const body = `Hi, \n\nCheck out this tour: ${linkToCopy} \n\nBest regards,`;
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };
  const handleSMS = () => {
    const message = `Check out this great tour: ${linkToCopy}`;
    const smsLink = `sms:?body=${encodeURIComponent(message)}`;
    window.location.href = smsLink;
  };

  const handleCategoryClick = (category) => {
    navigate("/adventure", {
      state: { categoryId: category.categoryId },
    });
  };

  const handleSubCategoryClick = (subCategory) => {
    navigate("/tourpage", {
      state: { categoryId: subCategory.categoryId },
    });
  };

  return (
    <>
      <Navbar pageName={pageName} />

      <div className="trip-page-container h-auto">
        <div className="main-container mb-3">
          <div className="container d-flex gap-5">
            {selectedThemeName && (
              <div className="text-style-schedule font-medium">
                <Link
                  to={`/${selectedThemeName}`}
                  className="clickable-link"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                >
                  <span className="clickable-link">{selectedThemeName}</span>
                </Link>{" "}
                {category && category?.categoryName && (
                  <span
                    onClick={() => handleCategoryClick(category)}
                    className="clickable-category clickable-link"
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  >
                    <IoChevronForward />
                    {category?.categoryName}
                  </span>
                )}
                {subCategory && subCategory?.subCategoryName && (
                  <span
                    onClick={() => handleSubCategoryClick(subCategory)}
                    className="clickable-subcategory clickable-link"
                    style={{ cursor: "pointer", fontSize: "20px" }}
                  >
                    <IoChevronForward />
                    {subCategory?.subCategoryName}
                  </span>
                )}
              </div>
            )}

            {taggedsubCategoryCategory
              .filter(
                (theme) =>
                  theme.theme.themeName === selectedThemeName &&
                  theme.category?.categoryId === selectedCategory &&
                  theme.subCategory?.subCategoryId === selectedSubCategory
              )
              .map((theme, index) => (
                <span className="badge font-medium px-0 mb-2" key={index}>
                  <Link
                    to={`/${selectedThemeName}`}
                    className="clickable-link"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                  >
                    {theme.theme.themeName}
                  </Link>{" "}
                  {theme.category?.categoryName && (
                    <span
                      onClick={() => handleCategoryClick(theme.category)}
                      className="clickable-category clickable-link"
                      style={{ cursor: "pointer", fontSize: "20px" }}
                    >
                      <IoChevronForward />
                      {theme.category?.categoryName}
                    </span>
                  )}
                  {theme.subCategory?.subCategoryName && (
                    <span
                      onClick={() => handleSubCategoryClick(theme.subCategory)}
                      className="clickable-subcategory clickable-link"
                      style={{ cursor: "pointer", fontSize: "20px" }}
                    >
                      <IoChevronForward />
                      {theme.subCategory?.subCategoryName}
                    </span>
                  )}
                </span>
              ))}
          </div>
        </div>

        <div className="mx-3 mx-lg-5">
          {images.length === 2 ? (
            <Row>
              {images
                .filter(
                  (asset) =>
                    asset.assetCategory === "FEATURED" ||
                    asset.assetCategory === "SNAPSHOTS"
                )
                .map((asset, index) => (
                  <Col key={index} md={6}>
                    <img
                      src={asset.assetUrl}
                      alt={`Image ${index}`}
                      className="img-fluid mt-3"
                      style={{
                        width: "100%",
                        height: "85%",
                        borderRadius: "13px",
                      }}
                    />
                  </Col>
                ))}
            </Row>
          ) : (
            <Row>
              <Col md={6}>
                <img
                  src={
                    images.find((asset) => asset.assetCategory === "FEATURED")
                      ?.assetUrl ||
                    images[0]?.assetUrl ||
                    defaultImage
                  }
                  alt="Main Image"
                  className="feature-schedule-image"
                />
                <div
                  style={{
                    position: "relative",
                    bottom: "50px",
                    right: "10px",
                  }}
                >
                  <button className="btn btn-share btn-outline-white ms-3">
                    {wishlist && (
                      <div onClick={() => removeFromWishlist()}>
                        <BookmarkFill style={{ color: "white" }} />
                        {!isMobileVisible && (
                          <span className="ms-1">Saved</span>
                        )}
                      </div>
                    )}
                    {!wishlist && (
                      <div onClick={() => addToWishlist()}>
                        <Bookmark />
                        {!isMobileVisible && (
                          <span className="ms-1"> Save</span>
                        )}
                      </div>
                    )}
                  </button>
                  <button
                    onClick={veiwSharePopup}
                    className="btn btn-share  btn-outline-white ms-3"
                  >
                    <img
                      src={share}
                      className="mb-1"
                      style={{
                        width: "20px",
                        height: "20px",
                        filter: "brightness(0) invert(1)",
                      }}
                    />
                    {!isMobileVisible && <span> Share</span>}
                  </button>
                </div>
              </Col>
              <Col md={6}>
                {!isMobileVisible && (
                  <div>
                    <Row>
                      {images.slice(0, 4).map((asset, index) => (
                        <Col key={index} md={6} className="mb-2 ps-0">
                          <div
                            style={{
                              height: "210px",
                              width: "100%",
                              overflow: "hidden",
                              borderRadius: "10px",
                              marginBottom: "0px",
                            }}
                          >
                            <img
                              src={asset.assetUrl}
                              alt={`Image ${index + 1}`}
                              className="img-fluid"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                marginTop: "0px",
                              }}
                            />
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                )}
                <div className="float-end veiw-Gallery">
                  <button
                    className="btn  btn-share btn-outline-white view-gallery-btn"
                    onClick={veiwGallery}
                  >
                    {!isMobileVisible && (
                      <span>View Gallery ({images.length})</span>
                    )}
                    {isMobileVisible && (
                      <div className="gallary-svg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-[20px] stroke-white md:hidden"
                          stroke="currentColor"
                          aria-hidden="true"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                          ></path>
                        </svg>
                      </div>
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col></Col>
            <Col></Col>
          </Row>
        </div>

        <Modal show={veiwImagesPopup} onHide={closeGallery} size="xl">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Row>
              {images.map((asset, index) => (
                <Col key={index} md={6} className="mb-4">
                  <img
                    src={asset.assetUrl}
                    alt={`Image ${index + 1}`}
                    style={{
                      width: "100%",
                      height: "300px",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </Col>
              ))}
            </Row>
          </Modal.Body>
        </Modal>

        <div className="trip-content ">
          <div className="trip-details-main">
            <div className=" mx-3 ms-md-5">
              <div className="mb-2">
                <span className="font-schedule-title text-wrap ">
                  {tourData.title}
                </span>
              </div>

              <div
                className="mt-3"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              >
                <GeoAlt className="text-muted font-duration-medium me-2" />
                <span className="font-medium">
                  {/* {tourData.formattedAddress} */}
                  {tourData.location?.address}
                </span>
              </div>

              <hr></hr>

              <div className="prose">
                <span className="text-gray-700 dark:text-gray-400 font-feature-tour">
                  {renderHighlights(
                    renderDescription(tourData.description, showFullDescription)
                  )}
                  {shouldShowLearnMore(tourData?.description) && (
                    <Button
                      className="view-btn"
                      variant="link"
                      onClick={handleLearnMore}
                    >
                      {showFullDescription ? "Show Less" : "Learn More"}
                    </Button>
                  )}
                </span>

                <Modal show={showModal} onHide={handlePopupClose} centered>
                  <Modal.Header closeButton>
                    <Modal.Title className="highlights-text">
                      Description
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="text-gray-700 dark:text-gray-400 font-feature-tour">
                    {renderHighlights(tourData.description)}
                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                </Modal>
              </div>

              {tourData?.highlights && (
                <div className="mt-4">
                  <span className="highlights-text">Highlights </span>
                  <div>
                    <p className="mt-1">
                      <span className="text-gray-700 dark:text-gray-400 font-feature-tour text-wrap">
                        {renderHighlights(highlightContent)}
                        {!showFullHighlights &&
                          tourData?.highlights?.length > highlightLimit && (
                            <Button
                              className="view-btn"
                              variant="link"
                              onClick={handleViewMore}
                            >
                              see more
                            </Button>
                          )}
                        {showFullHighlights && tourData?.highlights && (
                          <Button
                            className="view-btn"
                            variant="link"
                            onClick={handleViewLess}
                          >
                            see less
                          </Button>
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="trip-details mx-3 ms-md-5">
              <div>
                {tourData?.included && (
                  <div>
                    <span className="highlights-text">What's Included</span>
                    <ul className="list-unstyled ">
                      <li>
                        <span className="text-gray-700 dark:text-gray-400 font-feature-tour text-wrap">
                          {renderHighlights(includedContent)}
                          {!showFullIncluded &&
                            tourData?.included?.length > includedLimit && (
                              <Button
                                className="view-btn"
                                variant="link"
                                onClick={handleViewMoreIncluded}
                              >
                                see more
                              </Button>
                            )}
                          {showFullIncluded && tourData?.included && (
                            <>
                              <Button
                                className="view-btn"
                                variant="link"
                                onClick={handleViewLessIncluded}
                              >
                                see less
                              </Button>
                            </>
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                )}

                {tourData?.notIncluded && (
                  <div>
                    <span className="highlights-text">What's Not Included</span>
                    <ul className="list-unstyled ">
                      <li>
                        <span className="text-gray-700 dark:text-gray-400 font-feature-tour text-wrap">
                          {renderHighlights(notIncludedContent)}
                          {!showFullNotIncluded &&
                            tourData?.notIncluded?.length >
                              notIncludedLimit && (
                              <Button
                                className="view-btn"
                                variant="link"
                                onClick={handleViewMoreNotIncluded}
                              >
                                see more
                              </Button>
                            )}
                          {showFullNotIncluded && tourData?.notIncluded && (
                            <>
                              <Button
                                className="view-btn"
                                variant="link"
                                onClick={handleViewLessNotIncluded}
                              >
                                see less
                              </Button>
                            </>
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              {/* Booking Details Section for Mobile Screens */}
              <div className="booking-details-mobile d-block d-md-none mt-4 mb-3">
                {schedules.map((schedule) => (
                  <div onClick={() => handleScheduleDateClick(schedule)}>
                    <span
                      className={`tour-icon text-gray-700 dark:text-gray-400 font-feature-tour routes-btn ${
                        selectedSchedule === schedule ? "selected" : ""
                      }`}
                    >
                      <span className="font-medium">
                        <GeoAlt />
                      </span>{" "}
                      <span className="ms-2">
                        {schedule.scheduleTitle} on {schedule.startDate}(
                        {schedule.duration} Days)
                        {schedule.discount && (
                          <span>({schedule.discount}%)</span>
                        )}
                        {!schedule.discount && (
                          <span>
                            {" "}
                            {schedule.currency}&nbsp; {schedule.price}
                          </span>
                        )}
                        {schedule.finalPrice && schedule.discount && (
                          <span className="text-decoration-line-through">
                            {schedule.currency}&nbsp; {schedule.price}
                          </span>
                        )}
                        {schedule.finalPrice && schedule.discount && (
                          <span className="ms-2">
                            {schedule.currency}&nbsp;{schedule.finalPrice}
                          </span>
                        )}
                      </span>
                    </span>
                  </div>
                ))}
                <Row>
                  <div className="col-12">
                    <Form>
                      <Form.Group>
                        <Row>
                          {showParticipants && (
                            <div className="col-8">
                              {/* <Form.Label>Number Of Participants</Form.Label> */}
                              <Form.Control
                                type="text"
                                className="w-75 routes-btn"
                                name="numberOfParticipants"
                                placeholder="Number Of Participants"
                                onChange={handleChange}
                              />
                            </div>
                          )}
                        </Row>
                        <div
                          className=" text-end mt-2  position-relative"
                          style={{ width: "100px" }}
                        >
                          <Button
                            className="w-100"
                            onClick={handleBookNow}
                            disabled={!selectedScheduleId}
                          >
                            Book Me
                          </Button>
                        </div>
                      </Form.Group>
                    </Form>
                  </div>
                </Row>
                {!bookedTourId && (
                  <TourBooking
                    scheduleId={selectedScheduleId}
                    showBookPopup={showBookPopup}
                    handleClose={handleClose}
                  />
                )}
              </div>

              <div>
                <div>
                  <span className="highlights-text">Location</span>
                  <div className="media-map" ref={scheduleRef}>
                    {mapUrl && <MapView locationData={mapUrl} />}
                  </div>
                </div>

                <div className="media-map">
                  <>
                    <div ref={topObserverRef} style={{ height: "1px" }} />
                    <ScheduleItinerary
                      tourTittle={tourData.title}
                      schedules={schedules}
                      tourId={tourId}
                      isSticky={isSticky}
                    />
                    <div ref={bottomObserverRef} style={{ height: "1px" }} />
                  </>
                </div>

                {tourData?.additionalInformation && (
                  <div ref={additionalInfoRef} className="mt-4">
                    <span className="highlights-text">
                      Additional Information
                    </span>
                    <p>
                      <span className="text-gray-700 dark:text-gray-400 font-feature-tour text-wrap">
                        {renderHighlights(additionalInfoContent)}
                        {tourData?.additionalInformation?.length >
                          additionalInfoLimit && (
                          <>
                            {!showFullAdditionalInfo && (
                              <Button
                                className="view-btn"
                                variant="link"
                                onClick={handleViewMoreInfo}
                              >
                                see more
                              </Button>
                            )}
                            {showFullAdditionalInfo && (
                              <Button
                                className="view-btn"
                                variant="link"
                                onClick={handleViewLessInfo}
                              >
                                see less
                              </Button>
                            )}
                          </>
                        )}
                      </span>
                    </p>
                  </div>
                )}
              </div>

              <div>
                <span className="highlights-text">Cancellation Policy</span>
                <p>
                  <span className="text-gray-700 dark:text-gray-400 font-feature-tour">
                    {tourData?.cancellationPolicy?.policyName}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* Booking Details Section for Larger Screens */}
          {schedules && (
            <div className="booking-details me-5 d-none d-md-block ">
              {schedules.map((schedule) => (
                <div onClick={() => handleScheduleDateClick(schedule)}>
                  <span
                    className={`tour-icon text-gray-700 dark:text-gray-400 font-feature-tour routes-btn ${
                      selectedSchedule === schedule ? "selected" : ""
                    }`}
                  >
                    <span className="font-medium">
                      <GeoAlt />
                    </span>{" "}
                    <span className="ms-2">
                      {schedule.scheduleTitle} on {schedule.startDate}(
                      {schedule.duration} Days )
                      {schedule.discount && <span>({schedule.discount}%)</span>}
                      {!schedule.discount && (
                        <span>
                          {" "}
                          {schedule.currency}&nbsp; {schedule.price}
                        </span>
                      )}
                      {schedule.finalPrice && schedule.discount && (
                        <span className="text-decoration-line-through">
                          {schedule.currency}&nbsp;{schedule.price}
                        </span>
                      )}
                      {schedule.finalPrice && schedule.discount && (
                        <span className="ms-2">
                          {schedule.currency}&nbsp; {schedule.finalPrice}
                        </span>
                      )}
                    </span>
                  </span>
                </div>
              ))}
              <Row>
                <div className="col-12">
                  <Form>
                    <Form.Group>
                      <Row>
                        {showParticipants && (
                          <div className="col-8">
                            {/* <Form.Label>Number Of Participants</Form.Label> */}
                            <Form.Control
                              type="text"
                              className="w-75 routes-btn"
                              name="numberOfParticipants"
                              placeholder="Number Of Participants"
                              onChange={handleChange}
                            />
                          </div>
                        )}
                        <div className="col-4 text-end mt-2 position-relative">
                          <Button
                            onClick={handleBookNow}
                            disabled={!selectedScheduleId}
                          >
                            Book Me
                          </Button>
                        </div>
                      </Row>
                    </Form.Group>
                  </Form>
                </div>
                {/* <div className=" col-4 mt-5">
         
            </div> */}
              </Row>
              {!bookedTourId && (
                <TourBooking
                  scheduleId={selectedScheduleId}
                  showBookPopup={showBookPopup}
                  handleClose={handleClose}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <Modal show={showShareModal} onHide={closeSharePopup}>
        <Modal.Header closeButton>
          <Modal.Title className="highlights-text">
            Share this experience
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-2">
              <img
                src={images[0]?.assetUrl}
                alt="Main Image"
                className="scheduled-share-img"
                style={{ width: "52px", height: "52px", borderRadius: "10px" }}
              />
            </div>
            <div className="col-10">
              <span className="font-medium-title feature-title">
                {tourData.title}
              </span>
            </div>
          </Row>
          <Row className="mt-3">
            <Col>
              <div
                className="w-100 share-input-box d-flex align-items-center justify-content-center "
                variant="primary"
                role="button"
                onClick={handleCopyLink}
              >
                <img
                  src={linkImg}
                  className=""
                  style={{
                    color: "",
                    width: "15px",
                    height: "15px",
                  }}
                />
                <span className="ms-2 prose">Copy Link</span>
              </div>
            </Col>
            <Col>
              <div
                className="w-100 share-input-box justify-content-center d-flex align-items-center justify-content-center"
                role="button"
                onClick={handleWhatsAppShare}
              >
                <Whatsapp />
                <span className="ms-2">WhatsApp</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <div
                className="w-100 share-input-box justify-content-center d-flex align-items-center justify-content-center"
                variant="primary"
                role="button"
                onClick={handleEmailShare}
              >
                <img
                  src={emailImg}
                  className=""
                  style={{
                    color: "black",
                    width: "15px",
                    height: "15px",
                  }}
                />
                <span className="ms-2 prose">Email</span>
              </div>
            </Col>
            <Col>
              <div
                role="button"
                className="w-100 share-input-box justify-content-center d-flex align-items-center justify-content-center"
                onClick={handleSMS}
              >
                {" "}
                <img
                  src={messegeImg}
                  className=""
                  style={{
                    width: "15px",
                    height: "15px",
                  }}
                />
                <span className="ms-2 prose">Message</span>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <div className="margin-discover">
        <Discover />
        <Recomandation />
        <Footer />
      </div>
    </>
  );
};

export default ScheduleTour;
