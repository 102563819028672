import React from "react";
import {HashRouter as Router,Routes, Route,} from "react-router-dom";
import Header from "./Components/headers/Header";
import "./App.css";
import Register from "./Components/Login/Register";
import OAuth2RedirectHandler from "../src/Constatnts/OAuth2RedirectHandler";
import Profile from "./Components/pages/common/Profile";
import Adventure from "./Components/pages/adventure/Adventure";
import TransformHome from "./Components/pages/transformation/TransformHome";
import ExperienceHome from "./Components/pages/experience/ExprienceHome";
import SpiritualHome from "./Components/pages/spiritual/SpiritualHome";
import PersonalizedTrip from "./Components/pages/personalized-trip/PersonalizedTrip";
import TourPage from "./Components/pages/tours/TourPage";
import ScheduleTour from "./Components/pages/schedule-tour/ScheduleTour";
import BookedTours from "./Components/pages/booked-tour/BookedTours";
import TourBooking from "./Components/pages/booked-tour/TourBooking";
import HealthTour from "./Components/pages/personalized-trip/HealthTour";
import RelationshipTour from "./Components/pages/personalized-trip/RelationshipTour";
import BusinessTour from "./Components/pages/personalized-trip/BusinessTour";
import Terms from "./Components/footer/terms";
import Privacy from "./Components/footer/privacy";
import ScrollTop from "./Components/footer/scrolltop";
import Delete from "./Components/footer/delete";
import BookedTourPage from "./Components/pages/booked-tour/BookedTourPage";
import PaymentSuccessPage from "./Components/pages/schedule-tour/PaymentSuccessPage";
import MyWhishlist from "./Components/pages/wishlist/MyWhishlist";
import ContactUs from "./Components/footer/ContactUs";
import FaQ from "./Components/footer/FaQ";
import { TourProvider } from "./context/TourContext";
import Login from "./Components/Login/Login";
import RazorPay from "./Components/pages/schedule-tour/RazorPay";


function App() {
  return (
    <><Router>
      <TourProvider>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Header/>} />
          <Route path="/login" element={<Login />} />

          <Route path="/sign-up" element={<Register />} />
          <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />} />

          <Route path="/profile" element={<Profile />} />
          <Route path="/adventure" element={<Adventure />} />
          <Route path="/transformation" element={<TransformHome />} />
          <Route path="/experience" element={<ExperienceHome />} />
          <Route path="/spiritual" element={<SpiritualHome />} />
          <Route path="/personalizedTrip" element={<PersonalizedTrip />} />
          <Route path="/tourpage" element={<TourPage />} />
          <Route path="/tour/:tourId" element={<ScheduleTour />} />

          <Route path="/booking" element={<TourBooking />} />
          <Route path="/booking-details" element={<BookedTours />} />
          {/* <Route path="/paypal/success" element={<PaymentSuccess />} /> */}
          <Route path="/paypal/success" element={<PaymentSuccessPage />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/delete" element={<Delete />} />
          <Route path="health-tour" element={<HealthTour />} />
          <Route path="relationship-tour" element={<RelationshipTour />} />
          <Route path="business-tour" element={<BusinessTour />} />
          <Route path="/booked-tour-page" element={<BookedTourPage />} />
          <Route path="/whishlist-tour" element={<MyWhishlist />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<FaQ />} />

          <Route path="/razor-pay" element={<RazorPay />} />
        </Routes>
      </TourProvider>
    </Router>
    </>

  );
}

export default App;
