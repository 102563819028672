import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const TourBooking = ({ showBookPopup, handleClose, scheduleId }) => {
  
  const BaseAPI = process.env.REACT_APP_TOUR_SERVER_ENDPOINT;

  const userData = JSON.parse(localStorage.getItem("user"));
  const [showBookButton, setShowBookButton] = useState(false);

  const [formData, setFormData] = useState({
    numberOfParticipants: "",
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  // const handleBookByUser = async (e) => {
  //   const currentDate = new Date();
  //   const formattedDate = currentDate.toISOString();

  //   if (formData.numberOfParticipants === "") {
  //     toast.error("Please enter the number of participants");
  //     return;
  //   }

  //   const bookData = {
  //     numberOfParticipants: formData.numberOfParticipants,
  //     bookingDate: formattedDate,
  //     scheduleId: scheduleId,
  //     status: "BOOKED",
  //   };

  //   try {
  //     const response = await fetch(
  //       `${BaseAPI}/tour/user/${userData.userId}/schedule/${scheduleId}/book`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(bookData),
  //       }
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to book tour");
  //     }
  //     toast.success("Tour booked successfully!");
  //     navigate("/booking-details");
  //   } catch (error) {
  //     console.error("Error booking tour:", error);
  //   }
  // };

  const handlePaymentTour = async (e) => {
    e.preventDefault();
    console.log("Button clicked, initiating fetch...");


    if(userData == undefined){
      toast.warning("Please log in to your account to continue.");
      return;
    }
    if (formData.numberOfParticipants === "") {
      toast.error("Please enter the number of participants");
      return;
    }

    const bookData = {
      numberOfParticipants: formData.numberOfParticipants,
      status: "CONFIRMED",
    };
    

    try {
      const response = await fetch(
        `${BaseAPI}/tour/user/${userData.userId}/schedule/${scheduleId}/book`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookData),
        }
      );
      const data = await response.json();
      console.log("Response received:", data.statusMessage);
      if(data.statusCode == 400){
        toast.warning(data.statusMessage)
      }
      if(data.statusCode == 200){
        handlepayment();
      }
      

   
    } catch (error) {
      console.error("Error:", error);
       toast.error("Failed to initiate payment.");
    }
 
  };
  const handlepayment = async (e) => {
    const bookData = {
      numberOfParticipants: formData.numberOfParticipants,
      status: "CONFIRMED",
    };
    try {
      const response = await fetch(
        `https://eurekatrip-payment-service.azurewebsites.net/paypal/pay/user/${userData.userId}/schedule/${scheduleId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bookData),
        }
      );
      const data = await response.json();
      console.log("Response received:", data);
      

      if (response.ok) {
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        } else {
           toast.error("Redirect URL not found.");
        }
      } else {
         toast.error("error");
      }
    } catch (error) {
      console.error("Error:", error);
       toast.error("Failed to initiate payment.");
    }
  }
  return (
    <>
      <Modal show={showBookPopup} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Book Tour</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="p-3 register-form">
            <Form.Group>
              <Form.Label>Number Of Participants</Form.Label>
              <Form.Control
                type="text"
                name="numberOfParticipants"
                onChange={handleChange}
              />
            </Form.Group>
            <row>
              <Button
                className="mt-2"
                variant="primary"
                type="submit"
                onClick={handlePaymentTour}
              >
                Book Tour
              </Button>
            </row>
          </Form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default TourBooking;
