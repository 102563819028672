import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Modal,
  Form,
} from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../assets/css/recomandation.css";

const Recomandation = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const BaseAPI = process.env.REACT_APP_AUTH_SERVER_ENDPOINT;

  const handleShowModal = (title) => {
    setModalTitle(title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormErrors({});
    setFormData({
      name: "",
      email: "",
      mobileNumber: "",
      message: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneInputChange = (value, country) => {
    const numberWithoutCountryCode = value
      .replace(`+${country.dialCode}`, "")
      .trim();

    setFormData((prev) => ({
      ...prev,
      mobileNumber: numberWithoutCountryCode,
    }));

    validateMobileNumber(numberWithoutCountryCode);
  };

  

  const validateMobileNumber = (mobileNumber) => {
    const phoneRegex = /^\d+$/;
    if (
      !phoneRegex.test(mobileNumber) ||
      mobileNumber.length < 10 ||
      mobileNumber.length > 15
    ) {
      setFormErrors((prev) => ({
        ...prev,
        mobileNumber: "Please enter a valid mobile number.",
      }));
      return false;
    }
    setFormErrors((prev) => ({ ...prev, mobileNumber: "" }));
    return true;
  };

  const validateForm = () => {
    const errors = {};
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!emailPattern.test(formData.email)) {
      errors.email = "Please enter a valid email";
    }

    if (!formData.mobileNumber.trim()) {
      errors.mobileNumber = "Mobile number is required";
    } else if (!validateMobileNumber(formData.mobileNumber)) {
      errors.mobileNumber = "Please enter a valid mobile number.";
    }

    setFormErrors(errors);
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Authentication token not found. Please login.");
          return;
        }

        const response = await fetch(`${BaseAPI}/rest/auth/send-contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            title: modalTitle,
            name: formData.name,
            email: formData.email,
            mobileNumber: formData.mobileNumber,
            message: formData.message,
          }),
        });

        if (response.status === 401) {
          toast.error("Unauthorized. Please login again.");
          return;
        }

        if (response.status === 200) {
          const result = await response.json();
          toast.success(
            "Your message has been sent successfully! We'll get back to you. Stay in touch."
          );
          
          handleCloseModal();
        } else {
          const errorData = await response.json();
          toast.error(errorData.message || "Failed to submit the form.");
        }
      } catch (error) {
        toast.error("Error submitting the form. Please try again.");
      }
    }
  };

  return (
    <div className="recomandation-container font-common">
      <Container>
        <Row className="justify-content-center">
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center mt-3 mb-3"
          >
            <Card className="custom-card font-common">
              <Card.Body className="text-center mt-4">
                <Card.Title className="font-schedule-title fs-2 mt-4 mb-3 ms-4 fw-bold">
                  Find sponsorship for your adventure
                </Card.Title>
                <Card.Text className="mt-2 mb-5 font-medium text-center fw-bold">
                  Great way to organize an adventure trip.
                </Card.Text>
                <Button
                  className="custom-button"
                  onClick={() =>
                    handleShowModal("Find sponsorship for your adventure")
                  }
                >
                  <span className="recommendation-button">
                    FIND SPONSORSHIP
                  </span>
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            className="d-flex justify-content-center mt-3 mb-3 font-common"
          >
            <Card className="custom-card font-common">
              <Card.Body className="text-center mt-4">
                <Card.Title className="font-schedule-title fs-2 mt-4 mb-3 ms-4 fw-bold">
                  Get paid for your recommendations
                </Card.Title>
                <Card.Text className="mt-2 mb-5 font-medium text-center fw-bold">
                  Experts on EurekaTrip get paid when people book from their
                  guides.
                </Card.Text>
                <Button
                  className="custom-button"
                  onClick={() =>
                    handleShowModal("Get paid for your recommendations")
                  }
                >
                  <span className="recommendation-button">
                    BECOME AN EXPERT
                  </span>
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Modal for Popup */}
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton className="modal-title-main">
            <Modal.Title className="w-100 text-center font-common fw-bold" >
              {modalTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="custom-modal-body font-common">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formName">
                <Form.Label className="fw-bold">
                  Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  isInvalid={!!formErrors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label className="fw-bold">
                  Email <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  isInvalid={!!formErrors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3 mt-2">
                <Form.Label className="tour-form-label fw-bold">
                  Mobile Number <span className="text-danger">*</span>
                </Form.Label>
                <div className="d-flex align-items-center">
                  <PhoneInput
                    country={"in"}
                    value={formData.mobileNumber}
                    onChange={handlePhoneInputChange}
                    inputProps={{
                      name: "mobileNumber",
                      required: true,
                      autoFocus: true,
                    }}
                    inputStyle={{ width: "100%", height: "42px" }}
                    inputClass="form-control mobile-input"
                  />
                </div>
                {formErrors.mobileNumber && (
                  <div style={{ color: "red", fontSize: "small" }}>
                    {formErrors.mobileNumber}
                  </div>
                )}
              </Form.Group>

              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label className="fw-bold">Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter your message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer className="justify-content-center font-common">
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              variant=""
              className="bg-white build-trip-button text-wrap"
              onClick={handleSubmit}
            >
              <span className="trip-element text-wrap font-common">Submit</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <ToastContainer />
    </div>
  );
};

export default Recomandation;
